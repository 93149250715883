/* eslint-disable class-methods-use-this */
/* eslint-disable unicorn/prefer-spread */
import {
  ImageSource,
  LatLng,
  PromotionDtoSeriesLimited,
  PromotionDtoSeriesUnlimited,
  PromotionImageShape,
  PromotionSeriesType,
  SalesTypeDto,
  UonCollectable,
} from "@earthtoday/contract";
import { action, computed, makeObservable, observable } from "mobx";
import { TFunction } from "next-i18next";

import { CardItemTransactionCollectableDriver } from "../../components/CardItemTransactionCollectable/CardItemTransactionCollectable";
import { ITheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { IGiftCodeApi } from "../../shared/apis/GiftCodeApi";
import { buildResizedAwsImageRequest } from "../../shared/helpers/buildAwsImageRelated";
import {
  Direction,
  transformDigitToDegrees,
} from "../../shared/helpers/coordinates";
import { ReserveImageUrls } from "../../shared/models/Uon";
import { GroupProfileType, UserType } from "../../shared/models/User";
import {
  PROMOTION_IMAGE_ROUND_SHAPE_HEIGHT,
  PROMOTION_IMAGE_ROUND_SHAPE_WIDTH,
  PROMOTION_IMAGE_SQUARE_SHAPE_HEIGHT,
  PROMOTION_IMAGE_SQUARE_SHAPE_WIDTH,
  UON_CARD_HEIGHT,
  UON_CARD_WIDTH,
} from "../CardPromotionModel/CardPromotionBaseModel";
import { GivingPresenter } from "../GivingPresenter";
import { IModalStore } from "../ModalStore";
import { ProfilePagePresenter } from "../ProfilePagePresenter";
import { UserModel, UserModelConnectorDependencies } from "../UserModel";

export class CardItemTransactionCollectableModel
  implements CardItemTransactionCollectableDriver
{
  constructor(
    uonCollectable: UonCollectable,
    private modalStore: Pick<IModalStore, "openLazyModal">,
    private userProfile: UserModelConnectorDependencies | null,
    private theMessageStore: Pick<ITheMessageStore, "showMessage">,
    private giftCodeApi: Pick<
      IGiftCodeApi,
      "createGiftCode" | "unwrapGiftCode"
    >,
    private flags: {
      giveUonCardEnabled: boolean;
    },
    isMobileDevice: boolean,
    private statistic: {
      totalUons: number;
      totalBlocks: number;
    },
    private resetState: () => void,
    private profileStore: Pick<
      ProfilePagePresenter,
      | "isLastUonCard"
      | "userCounts"
      | "appendUonsTabData"
      | "filterUonsTabData"
      | "createUonModel"
      | "enableGiftLastUon"
    > | null,
    public zIndex: number = 0,
  ) {
    this.card = uonCollectable;
    this.isMobileDevice = isMobileDevice;
    this.givingStore = new GivingPresenter(
      this.card,
      this.modalStore,
      this.userProfile,
      this.theMessageStore,
      this.giftCodeApi,
      this.flags,
      this.isMobileDevice,
      this.statistic,
      this.resetState,
      this.profileStore || null,
    );
    makeObservable(this);
  }

  toJSON(): UonCollectable {
    return this.card;
  }

  imageSource = ImageSource.UON_IMAGE;

  shouldRenderContextMenu: boolean = false;
  isContextMenuActivated: boolean = false;
  onCardOverlayClicked(): void {}
  onEllipsisIconClicked(): void {}

  @observable card: UonCollectable;
  @observable isMobileDevice: boolean;

  @computed get salesType(): SalesTypeDto {
    return this.card.salesType || SalesTypeDto.RETAIL;
  }

  @computed get imageShape(): PromotionImageShape {
    return this.card.uonImageShape;
  }

  @computed get series():
    | PromotionDtoSeriesUnlimited
    | PromotionDtoSeriesLimited {
    return this.card.series;
  }
  @computed get bottomRightNote(): string | null {
    if (this.card.series.type === PromotionSeriesType.UNLIMITED) {
      return null;
    }
    return this.card.series.serialNumber + "/" + this.card.series.maxItems;
  }
  @computed get uonReserveSize(): number {
    return this.card?.count || 0;
  }

  @computed get numberOfUons(): string {
    return this.card.count?.toString() || "0";
  }

  @computed get topRightNote(): string {
    if (this.card.point) {
      return `${transformDigitToDegrees(
        this.card.point?.lat,
        Direction.Latitude,
      )} - ${transformDigitToDegrees(
        this.card.point?.lng,
        Direction.Longitude,
      )}`;
    }
    return "";
  }
  @computed get uonImageDimensions(): { width: number; height: number } {
    return this.card.uonImageShape === PromotionImageShape.ROUND
      ? {
          width: PROMOTION_IMAGE_ROUND_SHAPE_WIDTH,
          height: PROMOTION_IMAGE_ROUND_SHAPE_HEIGHT,
        }
      : {
          width: PROMOTION_IMAGE_SQUARE_SHAPE_WIDTH,
          height: PROMOTION_IMAGE_SQUARE_SHAPE_HEIGHT,
        };
  }
  @computed get promotionBgImageUrl(): string {
    if (this.card.imageSource === ImageSource.UON_IMAGE) {
      const imageData = {
        ...this.card.uonImage,
        awsBucket: this.card.uonImage.bucket,
        awsKey: this.card.uonImage.key,
        url: this.card.uonImage.baseUrl,
        isTransparent: this.card.uonImage.isTransparent ?? null,
        extract: undefined, // use original image for background
      };
      return buildResizedAwsImageRequest(
        imageData,
        {
          width: UON_CARD_WIDTH,
          height: UON_CARD_HEIGHT,
        },
        this.card.uonImage.baseUrl,
      );
    }

    return this.card.previewImage || "";
  }

  @computed get promotionUonImageUrl(): string {
    if (this.card.imageSource === ImageSource.UON_IMAGE) {
      const imageData = {
        ...this.card.uonImage,
        awsBucket: this.card.uonImage.bucket,
        awsKey: this.card.uonImage.key,
        url: this.card.uonImage.baseUrl,
        isTransparent: this.card.uonImage.isTransparent ?? null,
      };
      return buildResizedAwsImageRequest(
        imageData,
        this.uonImageDimensions,
        this.card.uonImage.baseUrl,
      );
    }

    return this.card.previewImage || "";
  }

  @computed get reserveName(): string {
    return this.card.meter?.reserve.name || "";
  }

  @computed get protectedBy(): string {
    return this.card.donatedNpo?.name || "";
  }

  @computed get registeredTo(): string {
    if (
      this.userProfile &&
      [UserType.BRAND, UserType.CHARITY, UserType.UNCLASSIFIED_ORG].includes(
        this.userProfile.userType,
      )
    ) {
      return this.userProfile.fullName;
    }

    return this.card.owner?.alias || this.userProfile?.fullName || "";
  }

  @computed get collectionName(): string {
    return this.card.collectionName;
  }
  @computed get collectionCreatedBy(): string {
    return this.card.collectionCreator.name;
  }

  @computed get id(): string {
    return this.card.id;
  }

  @computed get giftExpiredAt(): Date | null {
    if (!this.card.giftExpiredAt) {
      return null;
    }

    return new Date(this.card.giftExpiredAt);
  }

  @computed get isGiftActive(): boolean {
    if (!this.giftExpiredAt) return false;

    return this.giftExpiredAt > new Date();
  }

  @action.bound onGiveBtnClicked(): void {
    this.openGivingModal();
  }

  @computed get point(): LatLng | null {
    return this.card.point || null;
  }

  givingStore: GivingPresenter;

  @action.bound openGivingModal = (options?: { fadeAnimaion?: boolean }) => {
    this.givingStore.openGivingModal(options);
  };

  @action.bound toggleShareActive = (t: TFunction) => {
    this.givingStore.toggleShareActive(t);
  };

  @computed get vanityNameProtectedBy(): string {
    return this.card.donatedNpo?.vanityName || "";
  }
  @computed get vanityNameDonatedBy(): string {
    if (this.userProfile?.userType === UserType.BRAND) {
      return this.userProfile?.vanityName;
    }
    return (
      this.card.donatorConsumer?.vanityName ||
      this.card.donatorBrand?.vanityName ||
      this.card.owner?.vanityName ||
      ""
    );
  }
  @computed get vanityNameRegisteredTo(): string {
    if (
      this.userProfile &&
      [UserType.BRAND, UserType.CHARITY, UserType.UNCLASSIFIED_ORG].includes(
        this.userProfile.userType,
      )
    ) {
      return this.userProfile.vanityName;
    }

    return this.card.owner?.vanityName || "";
  }
  @computed get donatedBy(): string {
    if (this.userProfile?.userType === UserType.BRAND) {
      return this.userProfile?.fullName;
    }
    return (
      this.card.donatorConsumer?.alias ||
      this.card.donatorBrand?.name ||
      this.card.owner?.alias ||
      ""
    );
  }

  @computed get reserveImageUrl(): string | ReserveImageUrls {
    return this.card.reserveImageUrl || "";
  }
  @computed get isEndWithOneUonCard(): boolean {
    const splitNumber = String(this.uonReserveSize).split("");
    return Number(splitNumber[splitNumber.length - 1]) === Number(1);
  }

  @action.bound onVistButtonClicked() {
    this.resetState();
  }
}
