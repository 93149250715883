/* eslint-disable react/destructuring-assignment */
import appStore from "../../assets/img/download-on-app-store.png";
import googlePlay from "../../assets/img/download-on-google-play.png";
import { sc } from "./DownloadAppButton.styled";
interface IProps {
  store: "app-store" | "google-play";
  width: number;
  height: number;
  storeUrl?: string;
  dataCy?: string;
}

export const DownloadAppButton = (props: IProps) => {
  const { store, width, height, storeUrl = "", dataCy } = props;
  const imageUrl = store === "app-store" ? appStore : googlePlay;
  const pointerEvents = storeUrl ? "auto" : "none";

  return (
    <sc.Container
      height={height}
      width={width}
      pointerEvents={pointerEvents}
      data-cy={dataCy || `DownloadAppButton-${store}`}
    >
      <sc.StoreLink imageUrl={imageUrl} href={storeUrl} target="_blank">
        &nbsp;
      </sc.StoreLink>
      {!storeUrl && <sc.ComingSoonBanner>Coming Soon</sc.ComingSoonBanner>}
    </sc.Container>
  );
};
