/* eslint-disable class-methods-use-this */
import { action, computed, flow, makeObservable, observable } from "mobx";
import { scroller } from "react-scroll";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { CategoryPrincipleType } from "../../../__generated__/ts-gql/@schema";
import { CardItemLocation } from "../../components/CardItem/CardItem";
import { CardItemInfoDriver } from "../../components/CardItemInfo/CardItemInfo";
import { IModalDeleteCardStore } from "../../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import {
  CARD_OPEN_TYPE_ARTICLE_IMAGE_HEIGHT,
  CARD_OPEN_TYPE_ARTICLE_IMAGE_WIDTH,
} from "../../components/OpenCardTypeArticle/OpenCardTypeArticle.styled";
import {
  findViewport,
  Viewport,
} from "../../components/OpenCardTypeInfo/findViewport";
import { OpenCardTypeInfoDriver } from "../../components/OpenCardTypeInfo/OpenCardTypeInfo";
import { ITheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { IDeckDetailApi } from "../../shared/apis/DeckDetailApi";
import { etIceBlue } from "../../shared/colors";
import { cardInfoIFrameUrl } from "../../shared/env";
import { getCardDimensionsByCardSize } from "../../shared/helpers/getCardDimensionsByCardSize";
import { getResizeImageUrl } from "../../shared/helpers/getResizeImageUrl";
import { isBrowser } from "../../shared/helpers/isBrowser";
import { attempt, isError } from "../../shared/lodash";
import {
  CardInfo,
  CardSize,
  CardTextAlignment,
  CardTextColor,
  CardType,
  MetaData,
} from "../../shared/models/Card";
import { logger } from "../../shared/requireCrossEnv";
import { PageStore } from "../CardActionModel/CardActionModel";
import DeckDetailStore from "../DeckDetailStore/DeckDetailStore";
import { FeatureFlaggingData } from "../FeatureFlaggingStore";
import {
  handleDeepLinkClicked,
  parseUrlToActionResource,
} from "../handleDeepLinkClicked";
import { IModalStore, ModalType } from "../ModalStore";
import { CardInfoContextMenuStore } from "./CardInfoContextMenuStore";
export class CardInfoModel
  implements CardItemInfoDriver, OpenCardTypeInfoDriver
{
  type = "info";
  @observable public card: CardInfo;
  @observable isContextMenuActive: boolean = false;
  constructor(
    card: CardInfo,
    public modalStore: IModalStore,
    public deckDetailApi: IDeckDetailApi,
    private pageStore: PageStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    public modalDeleteCardStore: IModalDeleteCardStore,
    private featureFlagging: FeatureFlaggingData,
    private deckDetailStore: DeckDetailStore | null,
    private location?: CardItemLocation,
  ) {
    this.card = card;
    this.cardContextMenuDriver = new CardInfoContextMenuStore(
      this,
      this.modalStore,
      this.deckDetailApi,
      this.theMessageStore,
      this.userSessionStore,
      this.pageStore,
      this.modalDeleteCardStore,
      this.featureFlagging,
      this.deckDetailStore,
      this.location,
    );
    makeObservable(this);
  }
  toJSON(): CardInfo {
    return this.card;
  }
  get layoutType(): undefined {
    return undefined;
  }
  get isCardOwner(): boolean {
    if (!this.userSessionStore.user) return false;

    return this.userSessionStore.user.id === this.card.curator.id;
  }
  get contentType(): CardType.INFO {
    return CardType.INFO;
  }
  get isFeaturedDeck(): boolean {
    return false;
  }
  @computed get deckId(): string | undefined {
    return this.card.deck?.id;
  }
  @computed get id(): string {
    return this.card.id;
  }
  @computed get cardSize(): CardSize {
    return this.card.size;
  }
  @computed get componentKey(): string {
    return this.id + this.contentType.toString();
  }
  @computed get isEnableOpenCardView(): boolean {
    return this.card.enableOpenCardView;
  }
  @computed get categoryPrinciple(): CategoryPrincipleType {
    return this.card.principleName;
  }
  @computed get deckName(): string {
    return this.card.deck?.name || "";
  }
  @computed get deckNameWithHashTag(): string {
    return this.card.deck ? `#${this.card.deck.name}` : "";
  }
  @computed get deckPath(): string {
    return this.card.deck ? `/${this.card.deck.path.join("/")}` : "";
  }
  cardContextMenuDriver: CardInfoContextMenuStore;
  @action.bound toggleContextMenu(): void {
    if (!this.isContextMenuActive) {
      this.cardContextMenuDriver.getCardOrder();
    }
    this.isContextMenuActive = !this.isContextMenuActive;
  }
  @action.bound closeContextMenu(): void {
    this.isContextMenuActive = false;
  }
  @action.bound onContextMenuEllipsisClicked(): void {
    this.toggleContextMenu();
  }
  @action.bound onContextMenuFullscreenOverlayClicked(): void {
    this.isContextMenuActive = false;
  }
  @computed get title(): string {
    return this.card.title;
  }
  @computed get subtitle(): string | undefined {
    return this.card.subtitle;
  }
  @computed get bgImage(): string | null {
    if (!this.card.backgroundImage) {
      console.warn("Missing card background image"); // prevent: https://urbn8.slack.com/archives/G0166VBKT18/p1696992582682119?thread_ts=1696990817.364419&cid=G0166VBKT18
      return "";
    }

    return getResizeImageUrl(this.card.backgroundImage || null, {
      width: CARD_OPEN_TYPE_ARTICLE_IMAGE_WIDTH,
      height: CARD_OPEN_TYPE_ARTICLE_IMAGE_HEIGHT,
    });
  }
  @computed get backgroundImage(): string | undefined {
    if (!this.card.backgroundImage) return undefined;

    const cardDimensions = getCardDimensionsByCardSize(this.cardSize);
    return getResizeImageUrl(
      this.card.backgroundImage,
      cardDimensions,
      this.card.backgroundImage.url,
    );
  }
  @computed get backgroundColor(): string {
    return this.card.backgroundColor || etIceBlue;
  }
  @computed get textAlign(): CardTextAlignment {
    return this.card.textAlignment;
  }
  @computed get textColor(): CardTextColor {
    return this.card.textColor;
  }
  @computed get metadata(): MetaData {
    return {
      starred: false,
    };
  }
  @action.bound updateStarCard(starred: boolean) {}

  @computed get publishedDate(): string {
    return this.card.published || "";
  }

  getBaseUrl() {
    if (!isBrowser()) {
      return "";
    }

    const baseUrl = window.location.origin + window.location.pathname; // Combines origin and pathname to exclude query parameters
    return baseUrl;
  }

  @computed get iframeSrc(): string {
    if (!this.card.htmlBundleMetadata) {
      return "";
    }

    const timeStamp = this.card.updatedAt
      ? new Date(this.card.updatedAt).getTime()
      : Date.now();

    return (
      cardInfoIFrameUrl
        .replace("{userId}", this.card.curator.id)
        .replace("{cardId}", this.card.id) +
      `?timestamp=${timeStamp}&parentBaseUrl=${this.getBaseUrl()}`
    );
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  @action.bound onMessageReceived = flow(function* (
    this: CardInfoModel,
    event: { origin: string; data: string },
  ) {
    // Check the origin of the message for security purposes
    if (!this.iframeSrc.startsWith(event.origin)) {
      // this.webDriver?.iframeSrc example: https://users-1231231213123-cards-1234567890.urbn8.com?timestamp=1696496845443
      // if (event.origin !== "http://localhost:3000") {
      return; // Ignore the message
    }

    // Process the message
    const data = attempt(JSON.parse.bind(null, event.data.toString()));
    if (isError(data)) {
      logger.warn({ err: data }, "fail to parse json from open info iframe");
      return;
    }

    const actionResource = parseUrlToActionResource(data);

    yield* toFlowGeneratorFunction(handleDeepLinkClicked)(
      {
        actionResource,
        linkTarget: data.linkTarget || "_parent",
      },
      this.modalStore,
    );
  });

  @computed get viewports(): Viewport[] {
    return this.card.htmlBundleMetadata?.viewports || [];
  }

  @computed get curatorImageUrl(): string {
    return this.card.curator.image.url || "";
  }

  @computed get curatorName(): string {
    return this.card.curator.name;
  }

  @observable browserWidth = 0;
  @action.bound onBrowserWidthChanged(browserWidth: number) {
    this.browserWidth = browserWidth;
  }
  @computed get currentViewport(): Viewport | null {
    return findViewport(this.viewports, this.browserWidth) || null;
  }
  @observable shareActive = false;
  @action.bound onShareButtonClicked(options?: {
    duration: number;
    smooth: boolean;
    offset: number;
  }): void {
    this.toggleShareActive();

    if (this.shareActive) {
      this.scrollToShareContext(options);
    }
  }
  @action.bound toggleShareActive(): void {
    this.shareActive = !this.shareActive;
  }
  @computed get pathSharing(): string {
    return `${this.deckPath}/${this.card.id}`;
  }
  get shareTitle(): string {
    return "card-general.sharing.title";
  }

  scrollToShareContext(options?: {
    duration: number;
    smooth: boolean;
    offset: number;
  }): void {
    scroller.scrollTo("cardShare", options);
  }

  @action.bound openModal(modalType: ModalType): void {
    this.modalStore.openModal(modalType);
  }
}
