import { observer } from "mobx-react-lite";

import { sc } from "./SectionSeven.styled";
export const SectionSeven = observer(() => {
  return (
    <sc.Section className="visual-reg">
      <sc.SectionContent>
        <sc.Title>We are on a mission to protect 50% of the planet.</sc.Title>
        <sc.Description>
          Uon.Earth is an app developed by Union of Nature Foundation and
          EarthToday.com: a tech company with a purpose portal for companies,
          charities and organizations to collectively campaign for Earth.
        </sc.Description>
        <sc.ActionBlock>
          <sc.UnionOfNatureLink target="_blank" href="https://uon.org">
            Union of nature
          </sc.UnionOfNatureLink>
          <sc.EarthTodayLink target="_blank" href="https://earthtoday.com">
            EarthToday
          </sc.EarthTodayLink>
        </sc.ActionBlock>
      </sc.SectionContent>
    </sc.Section>
  );
});
