/* eslint-disable no-param-reassign */
import { DeckDetailResponseDto } from "@earthtoday/contract";

import { HomeCard } from "../../stores/HomeStore";
import { UonRangeParam } from "../../stores/LeaderBoardRangeModel";
import { RootStore } from "../../stores/rootStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { earthTodayDeckName, earthTodayProfileID, getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { isBrowser } from "../helpers/isBrowser";
import { Card, CardType } from "../models/Card";
import { IConsumer } from "../models/Consumer";
import { IDeckExplore } from "../models/DeckExplore";
import { User, UserProfile } from "../models/User";
import { uonCountUpdatedSubscription } from "./protectPageQueries";
import { UnsubscribeFn } from "./UnsubscribeFn";

export class HomeApi {
  constructor(
    private rootStore: RootStore,
    private tokenInterceptorStore: TokenInterceptorStore,
  ) {}

  fetchCards = async (): Promise<{
    cards: HomeCard[];
    profile: UserProfile;
    deck: IDeckExplore;
    metaTags: { [tagName: string]: string };
  }> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/feed?metaTags=${!isBrowser()}`,
    });

    if (!res.data.profile || !res.data.profile.id) {
      res.data.profile = await this.fetchProfileDetails();
    }
    if (!res.data.deck || !res.data.deck.id) {
      const deckResponse = await this.fetchDeckDetails();
      res.data.deck = deckResponse.data;
    }

    const cards = res.data.cards.map((card: HomeCard) => {
      return card.contentType === CardType.PROMOTION_REPOST
        ? card
        : {
            ...card,
            size: card.size?.toLowerCase(),
          };
    });
    return { ...res.data, cards };
  };

  fetchProtectedCount = async (): Promise<number> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/counts`,
    });
    return res.data.uonPreserved;
  };
  fetchLeaderboardTop = async (): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=TOP`,
    });
    return res.data;
  };
  fetchLeaderboardRecent = async (): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=RECENT`,
    });
    return res.data;
  };

  private fetchProfileDetails = async (): Promise<User> => {
    return this.rootStore.cache.get(`home_profile_${earthTodayProfileID}`, () =>
      this.rootStore.profileApi.fetchProfileDetail(earthTodayProfileID),
    );
  };

  private fetchDeckDetails = async (): Promise<DeckDetailResponseDto> => {
    return this.rootStore.cache.get(
      `home_profile_${earthTodayProfileID}_deck_${earthTodayDeckName}`,
      () =>
        this.rootStore.deckDetailApi.fetchDeckDetail(
          earthTodayProfileID,
          earthTodayDeckName,
        ),
    );
  };

  subscribeUonCount = (
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ uonCountUpdated: { count: number } }>({
        query: uonCountUpdatedSubscription() as any,
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.uonCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };

  fetchLeaderboardsCustomRange = async (
    range: UonRangeParam,
  ): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=RECENT&${range}`,
    });
    return res.data;
  };
}
