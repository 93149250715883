/* eslint-disable no-unused-expressions */
/* eslint-disable unicorn/consistent-function-scoping */
import {
  NotificationEventType,
  NotificationResponseDto,
  PromotionImageShape,
  SearchResponseDto,
} from "@earthtoday/contract";
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { ReactNode } from "react";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { INavbarApi } from "../../../shared/apis/NavbarApi";
import {
  CardNotificationAction,
  CardNotificationStatus,
} from "../../../shared/apis/navbarApiQueries";
import { UnsubscribeFn } from "../../../shared/apis/UnsubscribeFn";
import { smallScreenUpperLimitValue } from "../../../shared/breakpoints";
import { formatDateForNotification } from "../../../shared/helpers/formatDate";
import { translateAPIError } from "../../../shared/helpers/translateApiError";
import { ProfileTab } from "../../../shared/models/User";
import { snowplowTrackSiteSearch } from "../../../shared/snowplow";
import { AuthUserModel } from "../../../stores/AuthUserModel";
import { FeatureFlaggingData } from "../../../stores/FeatureFlaggingStore";
import {
  IModalCreateCard,
  IModalStore,
  LazyModalType,
  ModalType,
} from "../../../stores/ModalStore";
import { NewVisitorOverlayPresenter } from "../../../stores/NewVisitorOverlayPresenter/NewVisitorOverlayPresenter";
import { RootStore } from "../../../stores/rootStore";
import { DynamicLazyModalLoader } from "../../DynamicModalLoader/DynamicLazyModalLoader";
import { IUserSessionStore } from "../../ModalLogin/UserSessionStore";
import {
  ErrorMessage,
  ITheMessageStore,
  ToastMessageStatus,
} from "../../TheMessage/TheMessageStore";
import { QrCodeOverlayPresenter } from "./QrCodeOverlayPresenter";

export type SearchResultTab = "all" | "user" | "deck";

export enum ToolTipChannel {
  PEOPLE = "People - Channel to connect",
  CREATION = "Creation - Channel to inspire",
  WORLD = "World - Channel to inform",
  NATURE = "Nature - Channel to protect",
  KEYS = "Keys - Channel to discover",
  SPIRIT = "Spirit - Channel to grow",
  ETLOGO = "Play. Explore. Discover",
}

export class TheNavbarStore {
  @observable searchInputFocus: boolean = false;
  @observable searchInputValue: string = "";
  @observable searching: boolean = false;
  @observable searchResults: IObservableArray<SearchResponseDto> =
    observable<SearchResponseDto>([]);
  @observable searchResultFocus: boolean = false;
  @observable currentResultTabs: SearchResultTab = "all";

  @observable isLoadingNotification: boolean = false;
  @observable notifications: IObservableArray<NotificationResponseDto> =
    observable<NotificationResponseDto>([]);

  @observable showMenu: boolean = false;
  @observable showNotifications: boolean = false;

  @observable scrollYPosition = 0;
  @observable isLoadingReply: boolean = false;
  @observable isInvitationAccepted: boolean | null = null;
  @observable isInvitationReplied: boolean = false;
  @observable fetchNotificationError: ErrorMessage = null;
  @observable windowWith: number = 0;
  @observable hasUserProfileCollectible: boolean = false;

  unsubscribeFns: UnsubscribeFn[] = [];

  constructor(
    private navbarApi: INavbarApi,
    public modalStore: IModalStore,
    private userSessionStore: IUserSessionStore,
    private theMessageStore: ITheMessageStore,
    private featureFlagging: FeatureFlaggingData,
    private rootStore: RootStore,
  ) {
    makeObservable(this);
    this.newVisitorOverlayPresenter = new NewVisitorOverlayPresenter(
      this.rootStore.homeApi,
      this.featureFlagging,
    );
  }

  @computed get searchResultsForCurrentTab(): SearchResponseDto[] {
    return this.searchResults.filter((item) => {
      if (this.currentResultTabs !== "all") {
        return item.type === this.currentResultTabs;
      }
      return true;
    });
  }

  @computed get user(): AuthUserModel | null {
    return this.userSessionStore.user || null;
  }

  @computed get userLoggedIn(): boolean {
    return this.userSessionStore.user !== null;
  }
  @computed get userTermsAccepted(): boolean {
    return this.userSessionStore.user
      ? this.userSessionStore.user.termsAccepted
      : false;
  }

  @computed get hasAcceptedCookieConsent(): boolean {
    if (this.userSessionStore.user) {
      return true;
    }

    return (
      this.userSessionStore.hasAcceptedSocialCookies ||
      this.userSessionStore.hasAcceptedBasicCookies
    );
  }

  @computed get shouldRenderCenteredSearchBar(): boolean {
    if (this.windowWith < smallScreenUpperLimitValue) {
      return false;
    }
    return this.featureFlagging.flags.enableNewSearchPanel;
  }

  @computed get isCurator(): boolean {
    return this.userSessionStore.isCurator;
  }

  @computed get shouldRenderCreateCard(): boolean {
    return this.isCurator;
  }

  @computed get shouldRenderCreateActionCard(): boolean {
    return this.featureFlagging.flags.enableCreateActionCard && this.isCurator;
  }

  @computed get shouldRenderCreateInfoCard(): boolean {
    return this.featureFlagging.flags.enableCreateInfoCard && this.isCurator;
  }
  @computed get shouldRenderCreateCollectableCard(): boolean {
    return (
      this.featureFlagging.flags.enableCollectableUon &&
      this.userLoggedIn &&
      this.hasUserProfileCollectible
    );
  }

  @action.bound openModalCreateCard(): void {
    this.rootStore.mobileMenuStore.openLazyModal("cardCreate");
  }

  @action.bound showNotificationsToggle(): void {
    this.showNotifications = !this.showNotifications;
  }

  @action updateNotifications = (
    notifications: NotificationResponseDto[],
  ): void => {
    this.notifications.replace(notifications);
  };
  @action searchInputFocusUpdate = (b: boolean): void => {
    this.searchInputFocus = b;
  };
  @action searchInputValueUpdate = (s: string): void => {
    this.searchInputValue = s;
  };
  @action searchingUpdate = (b: boolean): void => {
    this.searching = b;
  };
  @action searchResultsUpdate = (searchResults: SearchResponseDto[]): void => {
    this.searchResults.replace(searchResults);
  };
  @action searchResultFocusUpdate = (b: boolean): void => {
    this.searchResultFocus = b;
  };
  @action currentResultTabsUpdate = (t: SearchResultTab): void => {
    this.currentResultTabs = t;
  };

  @action updateScrollYPosition = (pos: number): void => {
    this.scrollYPosition = pos;
  };

  @action openLazyModal = (): void => this.modalStore.openLazyModal();

  @action.bound openModal = (
    modalType: ModalType | LazyModalType | IModalCreateCard,
  ): void => {
    this.modalStore.openModal(modalType);
  };

  @computed get shouldRenderSignupButton(): boolean {
    return !this.featureFlagging.flags.enableLoginWithQRCode;
  }

  @action.bound onSigninButtonClicked(): void {
    this.openModal("signup");
  }

  @observable qrCodeOverlayDriver: QrCodeOverlayPresenter | null = null;
  @action.bound onLoginButtonClicked(isUnitTest?: boolean): void {
    if (this.featureFlagging.flags.enableLoginWithQRCode) {
      if (!this.qrCodeOverlayDriver) {
        this.qrCodeOverlayDriver = new QrCodeOverlayPresenter(
          this,
          this.theMessageStore,
          this.navbarApi,
          this.userSessionStore,
          this.rootStore.tokenStore,
        );
      }
      this.qrCodeOverlayDriver.onLoginButtonClicked(isUnitTest);
    } else {
      this.openModal("loginForm");
    }
  }

  @action.bound onOpenLoginModal(): void {
    this.onLoginButtonClicked();
  }

  @action.bound goBack = (): void => {
    this.modalStore.goBack();
  };

  @action.bound onLogout = flow(function* onLogout(
    this: TheNavbarStore,
    redirect: () => void,
  ) {
    yield this.userSessionStore.onLogout(redirect);
  });

  @action.bound onSearchInputChange(str: string): void {
    this.searchInputValueUpdate(str);
    if (str) {
      this.search(str);
    }
  }

  @action.bound search = flow(function* search(
    this: TheNavbarStore,
    str: string,
  ) {
    this.searching = true;

    const res: SearchResponseDto[] = yield this.navbarApi.fetchSearchResult(
      str,
    );

    this.searchResults.replace(res);

    snowplowTrackSiteSearch(str, res);

    this.searching = false;
  });

  @action.bound formatLatestTime = (
    fromDate: Date,
    notification: NotificationResponseDto,
  ): string => {
    return formatDateForNotification(fromDate, notification.latestTime);
  };

  @action.bound
  showMenuToggle(): void {
    this.showMenu = !this.showMenu;
  }

  @action.bound subscribe = (accessToken: string): void => {
    if (!this.user) {
      return;
    }

    this.unsubscribeFns.push(
      this.navbarApi.subscribeUserNotification(
        accessToken,
        this.user.id || "",
        (error, items) => {
          if (error) {
            this.fetchNotificationError = translateAPIError(error);
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            if (items && items.length > 0) {
              this.updateNotifications(items);
            }
            this.fetchNotificationError = null;
          });
        },
      ),
    );
    if (this.featureFlagging.flags.enableWebsocketCardEvents) {
      /* card event */
      this.unsubscribeFns.push(
        this.navbarApi.subscribeUserCardActionNotification(
          accessToken,
          this.user.id || "",
          (error, item) => {
            if (error) {
              this.fetchNotificationError = translateAPIError(error);
              this.theMessageStore.showMessage({
                typeMessage: "Error",
                title: "toast-message.general.error",
                content: translateAPIError(error),
              });
              return;
            }

            runInAction(() => {
              if (!item) return;

              if (
                item.action === CardNotificationAction.CREATE &&
                item.status === CardNotificationStatus.DRAFTING
              ) {
                this.theMessageStore.showMessage({
                  typeMessage: "Action",
                  title: "toast-message.save-card-as-draft.title",
                  status: ToastMessageStatus.SUCCESS,
                  content: "toast-message.save-card-as-draft.content",
                  actions: [
                    {
                      key: "cards",
                      name: "toast-message.general.action-draft",
                      href: "/[vanityName]",
                      as: `/${item.path[0]}?tab=${ProfileTab.ARCHIVE}`,
                    },
                  ],
                });
              }

              if (
                item.action === CardNotificationAction.CREATE &&
                item.status === CardNotificationStatus.PUBLISHED
              ) {
                this.theMessageStore.showMessage({
                  typeMessage: "Action",
                  title: "toast-message.create-card-success.title",
                  status: ToastMessageStatus.SUCCESS,
                  content: "toast-message.create-card-success.content",
                  vars: {
                    deckName: item.deckName,
                  },
                  actions: [
                    {
                      key: "cards",
                      name: "toast-message.create-card-success.view",
                      href: "/[vanityName]/[deckName]",
                      as: `/${item.path[0]}/${item.path[1]}`,
                    },
                  ],
                });
              }

              if (
                item.action === CardNotificationAction.UPDATE &&
                item.status === CardNotificationStatus.DRAFTING
              ) {
                this.theMessageStore.showMessage({
                  typeMessage: "Action",
                  title: "toast-message.save-card-as-draft.title",
                  status: ToastMessageStatus.SUCCESS,
                  content: "toast-message.save-card-as-draft.content",
                  actions: [
                    {
                      key: "cards",
                      name: "toast-message.save-card-as-draft.go-to-drafts",
                      href: "/[vanityName]",
                      as: `/${item.path[0]}?tab=${ProfileTab.ARCHIVE}`,
                    },
                  ],
                });
              }

              if (
                item.action === CardNotificationAction.UPDATE &&
                item.status === CardNotificationStatus.PUBLISHED
              ) {
                this.theMessageStore.showMessage({
                  typeMessage: "Close",
                  title: "toast-message.edit-card.title",
                  content: "toast-message.edit-card.content",
                });
              }

              if (item.action === CardNotificationAction.DELETE) {
                this.theMessageStore.showMessage({
                  typeMessage: "Close",
                  title: "toast-message.delete-card.title",
                  content: "toast-message.delete-card.content",
                });
              }

              this.fetchNotificationError = null;
            });
          },
        ),
      );
    }
  };

  @action.bound unsubscribe = (): void => {
    for (const unsubscribe of this.unsubscribeFns) unsubscribe();
  };

  @action.bound handleReplyGroupInvitation = flow(
    function* handleReplyGroupInvitation(
      this: TheNavbarStore,
      accepted: boolean,
      notification: NotificationResponseDto,
    ) {
      try {
        // replying
        this.isLoadingReply = true;
        yield this.navbarApi.replyGroupInvitation(accepted, notification);
        this.isInvitationAccepted = accepted;
        this.isInvitationReplied = true;

        // reading
        yield this.onMarkNotificationRead(notification);

        // show message
        const groupName = notification.initiators[0] || notification.group;
        accepted
          ? this.theMessageStore.showMessage({
              typeMessage: "Close",
              title: "toast-message.member-accepted.title",
              content: "toast-message.member-accepted.content",
              vars: {
                groupName,
              },
            })
          : this.theMessageStore.showMessage({
              typeMessage: "Close",
              title: "toast-message.member-rejected.title",
              content: "toast-message.member-rejected.content",
              vars: {
                groupName,
              },
            });
      } catch (error: any) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translateAPIError(error),
        });
      } finally {
        this.isLoadingReply = false;
      }
    },
  );

  onMount = async (): Promise<void> => {
    try {
      this.isLoadingNotification = true;
      const res = await this.navbarApi.fetchNotifications();
      this.updateNotifications(res);
    } catch (error: any) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error" || "Error",
        content: error as string,
      });
    } finally {
      this.isLoadingNotification = false;
    }
  };

  onMarkNotificationRead = async (
    notification: NotificationResponseDto,
  ): Promise<void> => {
    if (notification.visited) return;
    await this.navbarApi.markNotificationRead(notification);
  };

  turnMenuOff = (): void => {
    this.rootStore.mobileMenuStore.turnMenuOff();
  };

  @action getGiftCollectedNotificationTitle(
    notification: NotificationResponseDto,
  ): {
    key: string;
    vars: {
      uonCount: number;
    };
  } {
    return {
      key: "notification.collect-code",
      vars: {
        uonCount: notification.count,
      },
    };
  }

  @action getDeckAutoplayedNotificationTitle(
    notification: NotificationResponseDto,
  ): ReactNode {
    return (
      notification.event === NotificationEventType.AUTOPLAYED && (
        <span data-testid="text-content"> - {notification.description}</span>
      )
    );
  }

  @action.bound updateWindowWidth(width: number) {
    this.windowWith = width;
  }
  @action.bound fetchPromotionsCount = flow(function* (
    this: TheNavbarStore,
    vanityName?: string,
  ) {
    if (!this.user) return;

    const response = yield* toFlowGeneratorFunction(
      this.navbarApi.fetchPromotions,
    )(vanityName || this.user.id, true);

    if (!response.meta.count) return;
    this.hasUserProfileCollectible = true;
  });

  @action.bound onCreateActionCardMenuItemClicked() {
    if (!this.isCurator) return;

    this.modalStore.openLazyModal({
      name: "cardCreate",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import(
              "../../ModalCreateCardAction/ModalCreateCardActionSmart"
            ).then((component) => component.ModalCreateCardActionSmart)
          }
        />
      ),
    });
    this.showMenu = false;
  }

  @action.bound onCreateInfoCardMenuItemClicked() {
    if (!this.isCurator) return;

    this.modalStore.openLazyModal({
      name: "cardCreate",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import("../../ModalCreateCardInfo/ModalCreateCardInfoSmart").then(
              (component) => component.ModalCreateCardInfoSmart,
            )
          }
        />
      ),
    });
    this.showMenu = false;
  }

  @action.bound onCreateCollectableCardMenuItemClicked() {
    if (!this.shouldRenderCreateCollectableCard) return;

    this.modalStore.openLazyModal({
      name: "ModalCreateCardCollectable",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import(
              "../../ModalCreateCardCollectable/ModalCreateCardCollectableSmart"
            ).then((component) => component.ModalCreateCardCollectableSmart)
          }
          imageShape={PromotionImageShape.SQUARE}
        />
      ),
    });
    this.showMenu = false;
  }

  newVisitorOverlayPresenter: NewVisitorOverlayPresenter;
}
