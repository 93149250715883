import { observer } from "mobx-react-lite";

import { sc } from "./SectionFive.styled";
export const SectionFive = observer(() => {
  return (
    <sc.Section className="visual-reg">
      <sc.SectionContent>
        <sc.Title>Where the money goes</sc.Title>
        <sc.Subtitle>
          Simply put, €1.00 out of every €1.20 goes directly to the nature
          protection organizations
        </sc.Subtitle>
      </sc.SectionContent>
      <sc.PaymentBlock>
        <sc.Payment
          src={require("./assets/payment.png")}
          alt="payment details"
        />
      </sc.PaymentBlock>
    </sc.Section>
  );
});
