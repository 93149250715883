namespace zIndex {
  export const TOAST_MESSAGE: number = 9000;

  export const TOAST_MESSAGE_UNDERLAY: number = 8999;

  export const MODAL: number = 8000;

  export const OVERLAY_HOME_PAGE: number = 4999;

  export const FOOTER: number = 4999;

  export const CONTEXT: number = 2000;

  export const CARDSHARING_OVERLAY: number = 20;

  export const CARDSIMPLE_CONTEXT_CONTAINER: number = 17;

  export const CONTEXT_MENU_CARD_ARTICLE_ACTIVE: number = 20;

  export const CONTEXT_MENU_OPEN_CARD_ACTIVE: number = 20;

  export const CONTEXT_MENU_CARD_ARTICLE_INACTIVE: number = 9;

  export const CARDITEM_BLACK_OVERLAY: number = 16;

  export const CARDITEM_BLACK_OVERLAY_CARD_MUSIC: number = 14;

  export const CARDITEM_LINK: number = 12;

  export const CURATOR = 12;

  export const ACTION_CONTAINER = 12;

  export const CARDITEM_OVERLAY: number = 10;

  export const OVERLAY_LINK = 10;

  export const OVERLAY_ARTICLETYPEMUSIC: number = 8;

  export const HEADERCARD: number = 15;

  export const BACKSECTION_PROTECT_PAGE_BTN = 7;

  export const INTRO_VIDEO = 5100;

  // * NAVBAR
  export const NAVBAR: number = 5000;
  export const COOKIE_BANNER: number = 5002;

  export const CARDSIMPLE_CONTEXT_OVERLAY: number = 17;

  export const CARDSIMPLE_CONTEXT_BLACKOVERLAY: number = 13;
}

export default zIndex;
