import { observer } from "mobx-react-lite";

import { sc } from "./SectionFour.styled";
export const SectionFour = observer(() => {
  return (
    <sc.Section className="visual-reg">
      <sc.SectionContent>
        <sc.M2 />
        <sc.Title>
          For every uon you collect, we bring a new m² of nature under
          protection. <br />
          <b>This is how we scale.</b>
        </sc.Title>
        <sc.Subtitle>
          The protection is the responsibility of participating nature
          protection organizations (NPOs), which protect, rewild, or restore
          nature areas, while empowering local and indigenous communities.
        </sc.Subtitle>
        <sc.NpoLogos src={require("./assets/npo-logos.png")} alt="Npo Logos" />
      </sc.SectionContent>
    </sc.Section>
  );
});
