import { observer } from "mobx-react-lite";

import { DownloadAppButton } from "../../DownloadAppButtons/DownloadAppButton";
import { sc } from "./SectionEight.styled";

export interface SectionThreeDirver {
  shouldRenderAppStore: boolean;
  shouldRenderGooglePlay: boolean;
}
interface IProps {
  driver: SectionThreeDirver;
}

export const SectionEight = observer((props: IProps) => {
  const { shouldRenderAppStore, shouldRenderGooglePlay } = props.driver;

  return (
    <sc.Section className="visual-reg">
      <sc.SectionContent>
        <sc.LogoBlock>
          <a href="https://uon.earth">
            <sc.UonEarthLogo />
          </a>
          <sc.DownloadOptionsContainer>
            {shouldRenderAppStore && (
              <DownloadAppButton
                store="app-store"
                width={152}
                height={47}
                // storeUrl="https://apps.apple.com/app/uon-earth/id6470965614"
              />
            )}
            {shouldRenderGooglePlay && (
              <DownloadAppButton
                store="google-play"
                width={152}
                height={47}
                // storeUrl="https://play.google.com/store/apps/details?id=uon.earth"
              />
            )}
          </sc.DownloadOptionsContainer>
        </sc.LogoBlock>
        <sc.CopyrightBlock>
          <sc.CopyrightText>
            Copyright © {new Date().getFullYear()}
          </sc.CopyrightText>
          {/* <sc.SocialContacts>
            <sc.Logo
              data-cy="Footer-Facebook"
              title="Facebook"
              href="https://www.facebook.com/earthtoday/"
              target="_blank"
            >
              <IconSvg
                icon="facebook-logo"
                pointer
                width="20px"
                height="20px"
                padding="0"
                color={etNevadaGrey}
                hoverColor={etBrightLightBlue}
              />
            </sc.Logo>
            <sc.Logo
              data-cy="Footer-Instagram"
              title="Instagram"
              href="https://www.instagram.com/joinearthtoday/"
              target="_blank"
            >
              <IconSvg
                icon="instagram-v2"
                pointer
                width="20px"
                height="20px"
                padding="0"
                color={etNevadaGrey}
                hoverColor={etBrightLightBlue}
              />
            </sc.Logo>
            <sc.Logo
              data-cy="Footer-Twitter"
              title="Twitter"
              href="https://twitter.com/JoinEarthToday"
              target="_blank"
            >
              <IconSvg
                icon="twitter-v2"
                pointer
                width="20px"
                height="20px"
                padding="0"
                color={etNevadaGrey}
                hoverColor={etBrightLightBlue}
              />
            </sc.Logo>
          </sc.SocialContacts> */}
        </sc.CopyrightBlock>
        <sc.BreakLine />
        {/* <sc.PoliciesBlock>
          <sc.PolicyLink href="https://earthtoday.com/policies/disclaimer">
            Disclaimer
          </sc.PolicyLink>
          <sc.SplitLine>|</sc.SplitLine>
          <sc.PolicyLink href="https://earthtoday.com/policies/terms-and-conditions">
            Terms of service
          </sc.PolicyLink>
          <sc.SplitLine>|</sc.SplitLine>
          <sc.PolicyLink href="https://earthtoday.com/policies/privacy">
            Privacy Policy
          </sc.PolicyLink>
          <sc.SplitLine>|</sc.SplitLine>
          <sc.PolicyLink href="https://earthtoday.com/policies/cookie-policy">
            Cookie Policy
          </sc.PolicyLink>
        </sc.PoliciesBlock> */}
      </sc.SectionContent>
    </sc.Section>
  );
});
