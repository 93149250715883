/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable class-methods-use-this */
import { UserResponseDto } from "@earthtoday/contract";
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import QRCode from "qrcode";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { INavbarApi } from "../../../shared/apis/NavbarApi";
import { UnsubscribeFn } from "../../../shared/apis/UnsubscribeFn";
import { mediumScreenLowerLimitValue } from "../../../shared/breakpoints";
import { etBlack, etGreen } from "../../../shared/colors";
import { translateAPIError } from "../../../shared/helpers/translateApiError";
import { User } from "../../../shared/models/User";
import { ITokenStore } from "../../../stores/TokenStore";
import { IUserSessionStore } from "../../ModalLogin/UserSessionStore";
import { QrCodeOverlayDriver } from "../../QrCodeOverlay/QrCodeOverlay";
import { ITheMessageStore } from "../../TheMessage/TheMessageStore";
import { TheNavbarStore } from "./TheNavbarStore";

export class QrCodeOverlayPresenter implements QrCodeOverlayDriver {
  constructor(
    private theNavbarStore: TheNavbarStore,
    private theMessageStore: ITheMessageStore,
    public navbarApi: INavbarApi,
    public userSessionStore: IUserSessionStore,
    private tokenStore: ITokenStore,
  ) {
    makeObservable(this);
  }

  get isMobile(): boolean {
    return window.screen.width < mediumScreenLowerLimitValue;
  }

  get isIOS(): boolean {
    return /iphone/i.test(navigator.userAgent);
  }

  @observable shouldShowOverlay: boolean = false;
  @action.bound onCreateQrCode = flow(function* onCreateQrCode(
    this: QrCodeOverlayPresenter,
    isUnitTest?: boolean,
  ) {
    this.shouldShowOverlay = true;

    if (this.isQrCodeExpired) {
      yield* toFlowGeneratorFunction(this.getQRCodeData)();
      if (!isUnitTest) {
        this.createQrCode(); // prevent error "document is not defined" in unit test
      }
    }

    if (!this.isQrCodeExpired && !!this.qrUuid) {
      this.subscribeQrCode(this.qrUuid);
    }
    document.body.style.overflowY = "hidden";
  });
  @action.bound onLoginButtonClicked = flow(function* onLoginButtonClicked(
    this: QrCodeOverlayPresenter,
    isUnitTest?: boolean,
  ) {
    this.onCreateQrCode(isUnitTest);
  });

  @observable qrCodeUrl: string = "";
  @action.bound createQrCode(): void {
    const canvasSize = 304;
    const canvas = document.createElement("canvas");
    canvas.width = canvasSize;
    canvas.height = canvasSize;
    QRCode.toCanvas(canvas, this.qrUuid, {
      errorCorrectionLevel: "L",
      margin: 4,
      width: canvasSize,
      color: {
        dark: etBlack,
        light: etGreen,
      },
    });

    this.qrCodeUrl = canvas.toDataURL("image/jpeg");
  }

  @observable qrUuid: string = "";
  @observable expireTime: Date | null = null;
  @computed get isQrCodeExpired(): boolean {
    if (!this.expireTime) {
      return true;
    }
    return this.now.getTime() > this.expireTime.getTime();
  }

  @observable isLoading: boolean = false;
  @action.bound getQRCodeData = flow(function* getQRCodeData(
    this: QrCodeOverlayPresenter,
  ) {
    try {
      this.isLoading = true;

      const createdQrCodeData = yield* toFlowGeneratorFunction(
        this.navbarApi.createQrCode,
      )();

      this.qrUuid = createdQrCodeData.data.qrUuid;
      this.expireTime = new Date(createdQrCodeData.data.expiredAt);
      this.subscribeQrCode(this.qrUuid);
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      this.isLoading = false;
    }
  });

  @action.bound onReloadBtnClicked = flow(function* onReloadBtnClicked(
    this: QrCodeOverlayPresenter,
  ) {
    yield* toFlowGeneratorFunction(this.getQRCodeData)();
    this.createQrCode();
  });

  @action.bound onCloseButtonClicked(): void {
    this.shouldShowOverlay = false;
    this.unsubscribe();
    document.body.style.overflowY = "auto";
  }

  @observable now: Date = new Date();
  @action.bound updateNow(date: Date): void {
    this.now = date;
  }

  @computed get downloadLink(): string {
    return this.isIOS
      ? "https://apps.apple.com/app/uon-earth/id6470965614"
      : "https://play.google.com/store/apps/details?id=uon.earth";
  }

  @observable unsubscribeFns: UnsubscribeFn[] = [];
  @action.bound subscribeQrCode(uuid: string): void {
    this.unsubscribeFns.push(
      this.navbarApi.subscribeQrCode(
        uuid,
        (
          error,
          qrCodeActivated: {
            accessToken: string;
            refreshToken: string;
            user: UserResponseDto;
            status: "SUCCESS" | "EXPIRED";
          } | null,
        ) => {
          if (error) {
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            if (qrCodeActivated) {
              this.userSessionStore.setUser({
                ...qrCodeActivated.user,
                enableGiftLastUon: false, // TODO: add enableGiftLastUon to user in qrCodeActivated response
              } as User);
              this.tokenStore.setAccessToken(qrCodeActivated.accessToken);
              this.tokenStore.setRefreshToken(qrCodeActivated.refreshToken);
              this.theNavbarStore.onMount();
              this.resetState();
              this.unsubscribe();
            }
          });
        },
      ),
    );
  }

  @action.bound unsubscribe = (): void => {
    for (const unsubscribe of this.unsubscribeFns) unsubscribe();
  };

  resetState(): void {
    this.qrUuid = "";
    this.qrCodeUrl = "";
    this.expireTime = null;
    this.shouldShowOverlay = false;
    document.body.style.overflowY = "auto";
  }
}
