import { observer } from "mobx-react-lite";

import { LazyImage } from "../../LazyImage/LazyImage";
import { sc } from "./SectionSix.styled";
export const SectionSix = observer(() => {
  return (
    <sc.Section className="visual-reg">
      <sc.Globe>
        <LazyImage
          src={require("./assets/globe.png")}
          width="100%"
          maxWidth="100%"
          height="100%"
          maxHeight="100%"
          alt="nature needs half"
        />
      </sc.Globe>
      <sc.SectionContent>
        <sc.Title>Nature Needs Half</sc.Title>
        <sc.Subtitle>PROTECTING 50% OF THE PLANET BY 2050</sc.Subtitle>
        <sc.Description>
          After 100 years of conservation efforts, currently only 10% of the
          planet is protected. Scientists and conservationists stress that we
          need to protect 30% by 2030 and 50% by 2050 to safeguard biodiversity
          and secure our planet for future generations. We need to accelerate
          and scale up.
        </sc.Description>
      </sc.SectionContent>
    </sc.Section>
  );
});
