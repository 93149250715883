import { observer } from "mobx-react-lite";
import Head from "next/head";
import { PropsWithChildren } from "react";

import snippet from "../../../newrelic.snippet.txt";
import { isProduction } from "../../shared/env";
import { useTranslation } from "../../shared/translate/NextI18next";
import { MetaTagsStateful } from "../MetaTagsStateful/MetaTagsStateful";
import TheMessage from "../TheMessage/TheMessage";

export const LayoutLandingPage = observer((props: PropsWithChildren) => {
  const { t } = useTranslation("Meta");

  return (
    <>
      <Head>
        <title>{t("meta.uon-earth-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
        <meta name="description" content={t("meta.content")} />
        {snippet !== "" && isProduction === "true" ? (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html: snippet }}
          />
        ) : (
          <></>
        )}
        <link rel="icon" type="image/png" href="/icon/uon-earth.png" />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
          href="/launch/launch-max.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
          href="/launch/launch-xr.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
          href="/launch/launch-x.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
          href="/launch/launch-plus.png"
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          href="/launch/launch-8.png"
        />
        <meta name="msapplication-TileImage" content="/icon/uon-earth.png" />
      </Head>
      <MetaTagsStateful />
      <TheMessage />

      {props.children}

      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `if ('serviceWorker' in navigator) {
            window.addEventListener('load', function () {
              navigator.serviceWorker.getRegistrations().then(function(registrations) {
                for(let registration of registrations) {
                    registration.unregister();
                } 
            });
            })
          }`,
        }}
      />
    </>
  );
});
