/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/no-document-cookie */
import { action, configure, flow, makeObservable, observable } from "mobx";
import { enableStaticRendering } from "mobx-react-lite";
import { NextRouter } from "next/router";
import React from "react";
import { v4 } from "uuid";

import { initGoogleClient } from "../components/GoogleButton/GoogleButton";
import { ModalDeleteCardStore } from "../components/ModalDeleteCard/ModalDeleteCardStore";
import { ModalDeleteDeckStore } from "../components/ModalDeleteDeck/ModalDeleteDeckStore";
import {
  UserSessionData,
  UserSessionStore,
} from "../components/ModalLogin/UserSessionStore";
import { TheMessageStore } from "../components/TheMessage/TheMessageStore";
import { TheNavbarStore } from "../components/TheNavbarTop/TheNavbarStore/TheNavbarStore";
import { AutoplayDeckApi } from "../shared/apis/AutoplayDeckApi";
import { ICache } from "../shared/apis/CacheNoop";
import { CampaignDefaultApi } from "../shared/apis/CampaignDefaultApi";
import { CardApi } from "../shared/apis/CardApi";
import { CardDetailApi, ICardDetailApi } from "../shared/apis/CardDetailApi";
import { ClaimApi } from "../shared/apis/ClaimApi";
import { CreateCardApi } from "../shared/apis/CreateCardApi";
import DeckAPI from "../shared/apis/DeckApi";
import { DeckDetailApi } from "../shared/apis/DeckDetailApi";
import { FollowingApi } from "../shared/apis/FollowingApi";
import { GiftCodeApi, IGiftCodeApi } from "../shared/apis/GiftCodeApi";
import { GroupApi, IGroupApi } from "../shared/apis/GroupApi";
import { HomeApi } from "../shared/apis/HomeApi";
import { NavbarApi } from "../shared/apis/NavbarApi";
import { OverlayAboutApi } from "../shared/apis/OverlayAboutApi";
import { PaymentApi } from "../shared/apis/PaymentApi";
import PlayApi from "../shared/apis/PlayApi";
import { ProfileApi } from "../shared/apis/ProfileApi";
import { ProtectPageAPI as ProtectPageApi } from "../shared/apis/ProtectPageAPI";
import { ReserveApi } from "../shared/apis/ReserveApi";
import SendMailApi from "../shared/apis/SendMailApi";
import { SettingsApi } from "../shared/apis/SettingsApi";
import {
  ISubscriptionApi,
  SubscriptionApi,
} from "../shared/apis/SubscriptionApi";
import { SystemDeckApi } from "../shared/apis/SystemDeckApi";
import { UonCardDetailApi } from "../shared/apis/UonCardDetailApi";
import { UonManagementApi } from "../shared/apis/UonManagementApi";
import { UserSessionApi } from "../shared/apis/UserSessionApi";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../shared/constants";
import { isBrowser } from "../shared/helpers/isBrowser";
import {
  ErrorAPIType,
  getAPIErrorType,
  isAxiosError,
  translateAPIError,
} from "../shared/helpers/translateApiError";
import { isRunningJest } from "../shared/isRunningJest";
import { Logger } from "../shared/models/Logger";
import { AuthFormsStore } from "./AuthFormsStore";
import { AutoPlayDeckStore } from "./AutoplayDeckStore";
import CardDetailPresenter from "./CardDetailPresenter";
import { CounterStore } from "./CounterStore/CounterStore";
import DeckDetailStore from "./DeckDetailStore/DeckDetailStore";
import { DeviceStore } from "./DeviceStore";
import { DonateData, DonateStore } from "./DonateStore/DonateStore";
import FeatureFlaggingStore, {
  FeatureFlaggingData,
} from "./FeatureFlaggingStore";
import { GroupPresenter } from "./Group/GroupPresenter/GroupPresenter";
import { HomeStore } from "./HomeStore";
import { LocaleData, LocaleStore } from "./LocaleStore";
import { MetaData, MetaStore } from "./MetaStore";
import { MobileMenuStore } from "./MobileMenuStore/MobileMenuStore";
import { MockStore } from "./MockStore";
import { ModalOneFlowPresenter } from "./ModalOneFlowPresenter/ModalOneFlowPresenter";
import { ModalData, ModalStore } from "./ModalStore";
import { OverlayAboutStore } from "./OverlayAboutStore";
import { OverlaySystemPagePresenter } from "./OverlaySystemPagePresenter";
import { ProfilePagePresenter } from "./ProfilePagePresenter";
import { RestorePasswordFormStore } from "./RestorePasswordFormStore";
import { SubscriptionStore } from "./SubscriptionStore";
import { SystemDeckStore } from "./SystemDeckStore";
import { ThemeStore } from "./ThemeStore/ThemeStore";
import { TokenInterceptorStore } from "./TokenInterceptorStore";
import { ICookie, TokenStore } from "./TokenStore";
import { WindowStore } from "./WindowStore/WindowStore";

if (!isRunningJest()) {
  configure({
    enforceActions: "always",
    computedRequiresReaction: isBrowser(),
    observableRequiresReaction: isBrowser(),
  });
  enableStaticRendering(!isBrowser());
}

// const forbiddenCookieManager: ICookie = {
//   get(key: string): string {
//     throw new Error(`||| forbiddenCookieManager get: ${key}`);
//   },
//   set(key: string, value: string, options: CookieSerializeOptions) {
//    throw new Error(`||| forbiddenCookieManager set: ${key} value: ${value}`);
//   },
//   remove(key: string) {
//     throw new Error(`||| forbiddenCookieManager remove: ${key}`);
//   },
// };

export interface RootData {
  didInitLoginStatus: boolean;
  sessionId: string;
  userSessionStore: UserSessionData;
  modalStore: ModalData;

  donateStore: DonateData;
  featureFlaggingData: FeatureFlaggingData;
  metaData: MetaData;
  deviceData: Pick<
    DeviceStore,
    "isWebPSupported" | "isMobileDevice" | "isMobileWidth"
  >;
  mockData: Pick<MockStore, "mockApiBaseUrl" | "mockOptions">;
  localeData: LocaleData;
}

export class RootStore {
  logger: Logger;

  // change after call get me
  @observable didInitLoginStatus = false;

  @observable previousRoute: string = "";
  @observable isRunningTest = false;

  private sessionId: string;

  pageStore: HomeStore | null = null;
  authFormsStore: AuthFormsStore;

  restorePasswordFormStore: RestorePasswordFormStore;

  theNavbarStore: TheNavbarStore;

  profileStore: ProfilePagePresenter | null = null;

  deckDetailStore: DeckDetailStore | null = null;

  cardDetailStore: CardDetailPresenter | null = null;

  tokenStore: TokenStore;

  tokenInterceptorStore: TokenInterceptorStore;

  modalStore: ModalStore;

  metaStore: MetaStore;

  theMessageStore: TheMessageStore;

  modalDeleteDeckStore: ModalDeleteDeckStore;

  modalDeleteCardStore: ModalDeleteCardStore;

  userSessionStore: UserSessionStore;

  featureFlaggingStore: FeatureFlaggingStore;

  deviceStore: DeviceStore;
  mockStore: MockStore;

  themeStore: ThemeStore;

  counterStore: CounterStore;

  public uonManagementApi: UonManagementApi;

  public donateStore: DonateStore;

  public mobileMenuStore: MobileMenuStore;

  public userSessionApi: UserSessionApi;

  public settingsApi: SettingsApi;

  public PlayApi: PlayApi;

  public homeApi: HomeApi;

  public followingApi: FollowingApi;
  public claimApi: ClaimApi;

  public deckDetailApi: DeckDetailApi;

  public profileApi: ProfileApi;

  public cardDetailApi: ICardDetailApi;

  public uoncardDetailApi: UonCardDetailApi;
  public groupApi: IGroupApi;

  public giftCodeApi: IGiftCodeApi;

  public deckAPI: DeckAPI;

  public createCardApi: CreateCardApi;

  public cardApi: CardApi;

  public sendMailApi: SendMailApi;

  public protectPageApi: ProtectPageApi;

  public paymentApi: PaymentApi;
  public reserveApi: ReserveApi;

  public navbarApi: NavbarApi;

  public campaignDefaultApi: CampaignDefaultApi;

  public overlayAboutStore: OverlayAboutStore;
  public systemDeckApi: SystemDeckApi;
  public systemDeckStore: SystemDeckStore;

  public overlaySystemPagePresenter: OverlaySystemPagePresenter;

  public autoplayDeckStore: AutoPlayDeckStore;
  public autoplayDeckApi: AutoplayDeckApi;
  public oneFlowStore: ModalOneFlowPresenter;
  public groupStore: GroupPresenter;
  public subscriptionApi: ISubscriptionApi;
  public subscriptionStore: SubscriptionStore;
  public localeStore: LocaleStore;
  public windowStore: WindowStore;

  isHydrated: boolean;

  constructor(
    public cookieManager: ICookie,
    public cache: ICache,
    public device: Pick<
      DeviceStore,
      "isWebPSupported" | "isMobileDevice" | "isMobileWidth"
    >,
    private getCurrentUrl: () => string,
    public mockData: Pick<MockStore, "mockApiBaseUrl" | "mockOptions">,
    featureFlagsBase64: string,
    logger: Logger,
  ) {
    makeObservable(this);
    this.sessionId = cookieManager.get("sessionId");
    if (!this.sessionId) {
      this.sessionId = v4();
      cookieManager.set("sessionId", this.sessionId, {
        path: "/",
      });
    }

    this.logger = logger.child({
      sessionId: this.sessionId,
      container: "RootStore",
    });
    this.isHydrated = false;
    this.theMessageStore = new TheMessageStore();
    this.tokenStore = new TokenStore(this, cookieManager);
    this.tokenInterceptorStore = new TokenInterceptorStore(
      this,
      this.tokenStore,
    );

    this.featureFlaggingStore = new FeatureFlaggingStore(
      logger,
      featureFlagsBase64,
    );
    this.userSessionStore = new UserSessionStore(this);

    this.userSessionApi = new UserSessionApi(
      this.tokenInterceptorStore,
      this.tokenStore,
    );

    this.restorePasswordFormStore = new RestorePasswordFormStore(this);

    this.modalStore = new ModalStore();
    this.modalDeleteDeckStore = new ModalDeleteDeckStore(this);
    this.modalDeleteCardStore = new ModalDeleteCardStore(this);
    this.deviceStore = new DeviceStore(device);
    this.mockStore = new MockStore(mockData);
    this.metaStore = new MetaStore();

    this.authFormsStore = new AuthFormsStore(this);

    this.homeApi = new HomeApi(this, this.tokenInterceptorStore);

    this.followingApi = new FollowingApi(this.tokenInterceptorStore);

    this.deckDetailApi = new DeckDetailApi(
      this.tokenInterceptorStore,
      this.userSessionApi,
    );

    this.profileApi = new ProfileApi(
      this.tokenInterceptorStore,
      this.userSessionApi,
    );

    this.cardDetailApi = new CardDetailApi(
      this.tokenInterceptorStore,
      this.userSessionApi,
    );

    this.uoncardDetailApi = new UonCardDetailApi(
      this.tokenInterceptorStore,
      this.userSessionApi,
    );

    this.settingsApi = new SettingsApi(this.tokenInterceptorStore);

    this.deckAPI = new DeckAPI(this.tokenInterceptorStore);

    this.createCardApi = new CreateCardApi(this.tokenInterceptorStore);
    this.autoplayDeckApi = new AutoplayDeckApi(this.tokenInterceptorStore);

    this.PlayApi = new PlayApi(this.tokenInterceptorStore);

    this.cardApi = new CardApi(this.tokenInterceptorStore);

    this.sendMailApi = new SendMailApi(this.tokenInterceptorStore);

    this.protectPageApi = new ProtectPageApi(this.tokenInterceptorStore);

    this.reserveApi = new ReserveApi(this.tokenInterceptorStore);

    this.groupApi = new GroupApi(this.tokenInterceptorStore);

    this.giftCodeApi = new GiftCodeApi(this.tokenInterceptorStore);

    this.paymentApi = new PaymentApi(
      this.tokenInterceptorStore,
      this.tokenStore,
    );

    this.claimApi = new ClaimApi(this.tokenInterceptorStore, this.tokenStore);
    this.navbarApi = new NavbarApi(
      this.tokenInterceptorStore,
      this.userSessionStore,
    );
    this.campaignDefaultApi = new CampaignDefaultApi(
      this.tokenInterceptorStore,
    );
    this.theNavbarStore = new TheNavbarStore(
      this.navbarApi,
      this.modalStore,
      this.userSessionStore,
      this.theMessageStore,
      this.featureFlaggingStore,
      this,
    );

    this.mobileMenuStore = new MobileMenuStore(
      this.userSessionStore,
      this.deviceStore,
      this.theMessageStore,
      this.modalStore,
      this.featureFlaggingStore,
      this.theNavbarStore,
      this.profileApi,
      this.navbarApi,
    );

    this.groupApi = new GroupApi(this.tokenInterceptorStore);
    this.groupStore = new GroupPresenter(
      this.groupApi,
      this.modalStore,
      this.theMessageStore,
      this.userSessionStore,
      this.profileStore,
    );
    this.donateStore = new DonateStore(
      this.paymentApi,
      this.reserveApi,
      this.giftCodeApi,
      this.modalStore,
      this.userSessionStore,
      this.theMessageStore,
      this.featureFlaggingStore,
      this.deviceStore,
      this.profileStore,
      this.groupStore,
      getCurrentUrl,
      this.clearUserCountsCacheProfile,
    );

    this.themeStore = new ThemeStore(this.deviceStore);

    this.overlayAboutStore = new OverlayAboutStore(
      new OverlayAboutApi(this.tokenInterceptorStore),
      this.modalStore,
      this.theMessageStore,
      this.userSessionStore,
      this.featureFlaggingStore,
    );

    this.overlaySystemPagePresenter = new OverlaySystemPagePresenter(
      this.userSessionStore,
      this.modalStore,
      this.featureFlaggingStore,
      this.mobileMenuStore,
    );

    this.counterStore = new CounterStore(
      this.protectPageApi,
      this.profileApi,
      this.campaignDefaultApi,
      this.theMessageStore,
    );

    this.uonManagementApi = new UonManagementApi(this.tokenInterceptorStore);
    this.systemDeckApi = new SystemDeckApi(this.tokenInterceptorStore);
    this.systemDeckStore = new SystemDeckStore(
      this.systemDeckApi,
      this.modalStore,
      this.theMessageStore,
      this.donateStore,
      this.userSessionStore,
      this.featureFlaggingStore,
      this.mobileMenuStore,
    );
    this.autoplayDeckStore = new AutoPlayDeckStore(
      this.modalStore,
      this.createCardApi,
      this.autoplayDeckApi,
      this.theMessageStore,
      this.featureFlaggingStore,
      this.userSessionStore,
      this.deckDetailStore || undefined,
      this.profileStore || undefined,
    );

    this.oneFlowStore = new ModalOneFlowPresenter(
      null,
      this.paymentApi,
      this.groupApi,
      this.modalStore,
      this.theMessageStore,
      this.userSessionStore,
      this.featureFlaggingStore,
      this.profileApi,
      this.deckDetailApi,
      this.tokenStore,
      this.userSessionApi,
      this.theNavbarStore,
      this.groupStore,
      this.donateStore,
      this.logger,
    );

    this.subscriptionApi = new SubscriptionApi(this.tokenInterceptorStore);
    this.subscriptionStore = new SubscriptionStore(this.subscriptionApi);
    this.localeStore = new LocaleStore(logger);
    this.windowStore = new WindowStore();

    initGoogleClient({
      onGoogleSuccess: async (googleToken: string) => {
        try {
          await this.userSessionStore.loginWithGoogle(googleToken);
          this.theNavbarStore.onMount();
        } catch (error) {
          const errorMessage = translateAPIError(error);
          const errorType = isAxiosError(error) ? getAPIErrorType(error) : "";
          const errorTitle =
            errorType === ErrorAPIType.SocialSignupEmailExistsException
              ? "toast-message.translate-api-error.social-signup-failed-title"
              : "toast-message.general.error";

          this.theMessageStore.showMessage(
            {
              typeMessage: "Error",
              title: errorTitle,
              content: typeof errorMessage === "string" ? errorMessage : "",
            },
            {
              closeDuration: "never",
            },
          );
        }
      },
      onGoogleFailure: (err) => {
        logger.error({ err }, "google login fail:");
      },
    });
  }

  @action.bound
  clearUserCountsCacheProfile(): void {
    this.profileStore?.fecthUserCountsById(true);
  }

  dehydrate(): RootData {
    return {
      didInitLoginStatus: this.didInitLoginStatus,
      sessionId: this.sessionId,
      userSessionStore: this.userSessionStore.dehydrate(),
      modalStore: this.modalStore.dehydrate(),
      donateStore: this.donateStore.dehydrate(),
      featureFlaggingData: this.featureFlaggingStore.dehydrate(),
      metaData: this.metaStore.dehydrate(),
      deviceData: this.deviceStore.dehydrate(),
      mockData: this.mockStore.dehydrate(),
      localeData: this.localeStore.dehydrate(),
    };
  }

  @action.bound public hydrate(data: RootData): void {
    this.didInitLoginStatus = data.didInitLoginStatus;
    this.sessionId = data.sessionId;
    this.userSessionStore.hydrate(data.userSessionStore);
    this.modalStore.hydrate(data.modalStore);
    this.donateStore.hydrate(data.donateStore);
    this.featureFlaggingStore.hydrate(data.featureFlaggingData);
    this.metaStore.hydrate(data.metaData);
    this.deviceStore.hyrate(data.deviceData);
    this.mockStore.hydrate(data.mockData);
    this.localeStore.hydrate(data.localeData);
  }

  @action.bound initSessionState = flow(function* initSessionState(
    this: RootStore,
  ) {
    this.migrateCookieToLocalstorage();
    yield this.userSessionStore.initLoginStatus();
    this.didInitLoginStatus = true;
  });

  @action.bound setPageStore(store: HomeStore): void {
    this.pageStore = store;
  }
  @action.bound removePageStore(): void {
    this.pageStore = null;
  }
  @action.bound setProfileStore(store: ProfilePagePresenter): void {
    this.profileStore = store;
  }

  @action.bound removeProfileStore(): void {
    this.profileStore = null;
  }

  @action.bound setCardDetailStore(store: CardDetailPresenter): void {
    this.cardDetailStore = store;
  }

  @action.bound removeCardDetailStore(): void {
    this.cardDetailStore = null;
  }

  @action.bound setIsRunningTest(b: boolean): void {
    this.isRunningTest = b;
  }

  @action.bound setDeckDetailStore(store: DeckDetailStore): void {
    this.deckDetailStore = store;
  }

  @action.bound removeDeckDetailStore(): void {
    this.deckDetailStore = null;
  }

  migrateCookieToLocalstorage(): void {
    // migrate cookie to localstorage
    // this function only call in browser, so document.cookie is defined
    if (this.cookieManager.get(ACCESS_TOKEN_KEY)) {
      this.tokenStore.setAccessToken(this.cookieManager.get(ACCESS_TOKEN_KEY));
      this.cookieManager.remove(ACCESS_TOKEN_KEY, { path: "/" });
      document.cookie =
        `${ACCESS_TOKEN_KEY}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;` +
        "path=/";
    }

    if (this.cookieManager.get(REFRESH_TOKEN_KEY)) {
      this.tokenStore.setRefreshToken(
        this.cookieManager.get(REFRESH_TOKEN_KEY),
      );
      this.cookieManager.remove(REFRESH_TOKEN_KEY, { path: "/" });
      document.cookie =
        `${REFRESH_TOKEN_KEY}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;` +
        "path=/";
    }
  }
}

const rootStoreContext = React.createContext<RootStore | null>(null);

export const Provider = (props: {
  children: React.ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  router: NextRouter;
  rootStore: RootStore;
}) => {
  return (
    <rootStoreContext.Provider value={props.rootStore}>
      {props.children}
    </rootStoreContext.Provider>
  );
};

export const useRootStore = () => {
  const store = React.useContext(rootStoreContext);
  if (!store) {
    throw new Error("useRootStore must be use in Provider");
  }

  return store;
};
