export type ViewPortLargeScreenUpperLimit = "1383.98px";
export type ViewPortLargeScreenLowerLimit = "1032px";
export type ViewPortMediumScreenUpperLimit = "1031.98px";
export type ViewPortMobileDeviceWidth = "428px";
export type ViewPortMaxWidth = "100000px";

export const xLargeScreenLowerLimitValue = 1384;
export const xLargeScreenLowerLimit = `${1384}px`;
export const largeScreenUpperLimitValue = 1383.98;
export const largeScreenUpperLimit: ViewPortLargeScreenUpperLimit = `${largeScreenUpperLimitValue}px`;
export const largeScreenLowerLimitValue = 1032;
export const largeScreenLowerLimit: ViewPortLargeScreenLowerLimit = `${largeScreenLowerLimitValue}px`;
export const mediumScreenUpperLimitValue = 1031.98;
export const mediumScreenUpperLimit: ViewPortMediumScreenUpperLimit = `${mediumScreenUpperLimitValue}px`;

export const mobileMaxHeight = "1023.98px";
export const xmediumScreenLowerLimitValue = 768;
export const xmediumScreenLowerLimit = `${xmediumScreenLowerLimitValue}px`;
export const mediumScreenLowerLimitValue = 680;
export const mediumScreenLowerLimit = `${mediumScreenLowerLimitValue}px`;
export const smallScreenUpperLimitValue = 679.98;
export const smallScreenUpperLimit = `${smallScreenUpperLimitValue}px`;
export const mobileDeviceWidth: ViewPortMobileDeviceWidth = "428px";
export const smallScreenBalanceLimit = "375px";
export const smallScreenLowerLimitValue = 328;
export const smallScreenLowerLimit = "328px";

export const BREAK_POINT_ACTIVE_DARK_MODE_VALUE = 680;
export const BREAK_POINT_ACTIVE_DARK_MODE = `${BREAK_POINT_ACTIVE_DARK_MODE_VALUE}px`;

export const VIEWPORT_MAX_WIDTH: ViewPortMaxWidth = "100000px";
