import getConfig from "next/config";

import { ETSessionStorage } from "./helpers/EtStorages";
import { isBrowser } from "./helpers/isBrowser";

const { publicRuntimeConfig } = getConfig();

export const getEnv = (): string => {
  return publicRuntimeConfig.NODE_ENV || "";
};

export const getAPIBaseUrl = (): string => {
  if (isBrowser() && ETSessionStorage.getItem("MOCK_API_BASE_URL")) {
    return `${ETSessionStorage.getItem("MOCK_API_BASE_URL")}/v1`;
  }

  return publicRuntimeConfig.REACT_APP_API_BASE_URL || "";
};

export const getAPIRootBaseUrl = (): string => {
  const baseUrl = getAPIBaseUrl();
  if (baseUrl.endsWith("/v1")) {
    return baseUrl.split("/v1")[0];
  }

  return baseUrl;
};

export const getV2APIBaseUrl = (): string => {
  return publicRuntimeConfig.REACT_APP_API_V2_BASE_URL || "";
};

export const getGraphqlAPIBaseUrl = (): string => {
  return (
    publicRuntimeConfig.REACT_APP_GRAPHQL_API_BASE_URL ||
    "http://localhost:3002/graphql"
  );
};

export const getGraphqlWsBaseUrl = (): string => {
  return (
    publicRuntimeConfig.REACT_APP_GRAPHQL_WS_BASE_URL ||
    "ws://localhost:3002/graphql"
  );
};

export const getIntroVideoUrl = (): string => {
  return (
    publicRuntimeConfig.INTRO_VIDEO_URL ||
    "https://www.youtube.com/embed/L4zpTkDpFro"
  );
};

export const isProduction: string =
  publicRuntimeConfig.REACT_APP_PRODUCTION || "";

export const isTranslateEnv: boolean =
  publicRuntimeConfig.IS_TRANSLATE_ENV === "true";
export const googleClientID: string =
  publicRuntimeConfig.REACT_APP_GOOGLE_CLIENT_ID ||
  "316269426599-1ogg1uo2mmrs6uvbi6h1j8urc4lfg96s.apps.googleusercontent.com";
export const facebookAppID: string =
  publicRuntimeConfig.REACT_APP_FACEBOOK_APP_ID || "";
export const host: string =
  publicRuntimeConfig.REACT_APP_HOST || "http://localhost:4200";

export const deepLinkHost: string =
  publicRuntimeConfig.REACT_APP_DEEP_LINK_HOST || "https://app.urbn8.io";

export const stripePublicKey: string =
  publicRuntimeConfig.REACT_APP_STRIPE_PUBLIC_KEY ||
  "pk_test_51JAmkHCOMhioeKVhDVAFm7cWPq2fSEJZSXNAte9jec9DRlVyHwzSuJw4RqshUtQOsUhuwFvcRdKREnxiJZXlptMn001s5UdQDK";
export const molliePublicKey: string =
  publicRuntimeConfig.REACT_APP_MOLLIE_PUBLIC_KEY ||
  "test_8hPDVyKzNH225rurNSp3KgNPRr7vv5";
export const earthTodayProfileID: string =
  publicRuntimeConfig.REACT_APP_EARTHTODAY_PROFILE_ID || "";
export const earthTodayDeckName: string =
  publicRuntimeConfig.REACT_APP_EARTHTODAY_DECK_NAME || "";
export const featureFlaggingEnabled = (): boolean => {
  if (!isBrowser() && process.env.NODE_ENV === "test") {
    return process.env.REACT_APP_FEATURE_FLAGGING_ENABLED === "true";
  }

  return publicRuntimeConfig.REACT_APP_FEATURE_FLAGGING_ENABLED === "true";
};
export const splitIOJavascriptSDKKey: string =
  publicRuntimeConfig.REACT_APP_SPLITIO_JAVASCRIPT_SDK_KEY || "";
export const connectionAlertEmailTo: string =
  publicRuntimeConfig.CONNECTION_ALERT_EMAIL_TO || "";
export const financialAlertEmailFrom: string =
  publicRuntimeConfig.FINANCIAL_ALERT_EMAIL_FROM || "";
export const financialAlertEmailTo: string =
  publicRuntimeConfig.FINANCIAL_ALERT_EMAIL_TO || "";
export const connectionAlertEmailFrom: string =
  publicRuntimeConfig.CONNECTION_ALERT_EMAIL_FROM || "";
export const connectionAlertBypassEmailTo: string =
  publicRuntimeConfig.CONNECTION_ALERT_BYPASS_EMAIL_TO || "";
export const connectionAlertBypassEmailFrom: string =
  publicRuntimeConfig.CONNECTION_ALERT_BYPASS_EMAIL_FROM || "";
export const partnerAlertEmailTo: string =
  publicRuntimeConfig.PARTNER_ALERT_EMAIL_TO || "";
export const partnerAlertEmailFrom: string =
  publicRuntimeConfig.PARTNER_ALERT_EMAIL_FROM || "";
export const partnerAlertBypassEmailTo: string =
  publicRuntimeConfig.PARTNER_ALERT_BYPASS_EMAIL_TO || "";
export const partnerAlertBypassEmailFrom: string =
  publicRuntimeConfig.PARTNER_ALERT_BYPASS_EMAIL_FROM || "";
export const mapboxId: string =
  publicRuntimeConfig.REACT_APP_MAPBOX_ID ||
  "pk.eyJ1Ijoiam9yaXNlYXJ0aHRvZGF5IiwiYSI6ImNrMjFoeGY5aDFhZ2IzZXVnY25nbmNlNWIifQ.bsFpboBSyiwLSgOALI2YXg";

export const w3wKey: string = publicRuntimeConfig.REACT_APP_W3W_KEY || "";

export const urlCardTheBigWhy: string =
  publicRuntimeConfig.REACT_URL_CARD_THE_BIG_WHY ||
  "earthtoday/thebigwhy/781233336185330689";

export const urlCardTheEarthFlag: string =
  publicRuntimeConfig.REACT_URL_CARD_THE_EARTHFLAG ||
  "https://earthtoday.com/foundation/aboutearthtoday/1930439644106711040";

export const urlVimeoSectionThree: string =
  publicRuntimeConfig.REACT_URL_VIMEO_SECTION_THREE ||
  "https://vimeo.com/733609612";
export const baseImageUrl: string =
  publicRuntimeConfig.REACT_APP_BASE_IMAGE_URL ||
  "https://cdn-images.earthtoday.com";
export const bgUonImageUrl: string =
  publicRuntimeConfig.REACT_APP_PROTECT_PAGE_BG_UON_IMAGE_URL ||
  "https://api.uon.store/api/member/reserves/121/images/FAR9k6mifWPv";
export const googleAnalyticsContainer: string =
  publicRuntimeConfig.REACT_APP_GOOGLE_ANALYTICS_CONTAINER || "GTM-MDH6XD7";

export const utmSourceValue: string = publicRuntimeConfig.UTM_SOURCE_VALUE
  ? `utm_source=${publicRuntimeConfig.UTM_SOURCE_VALUE}&utm_medium=referral`
  : "utm_source=et-urbn8&utm_medium=referral";

export const cacheFirstHitMaxAge: string =
  publicRuntimeConfig.CACHE_FIRST_HIT_MAX_AGE || "60";

export const isVisualSanityTestRunning: boolean =
  publicRuntimeConfig.VISUAL_SANITY_TEST_RUNNING === "true";

export const saltTest: string = process.env.SALT_TEST || "#SALT_TEST#";

export const appENV: string = publicRuntimeConfig.APP_ENV || "";

export const sentryDSN: string = publicRuntimeConfig.SENTRY_DSN || "";

export const sentryRelease: string = publicRuntimeConfig.SENTRY_RELEASE || "";

export const snowplowCollectorEndpoint: string =
  publicRuntimeConfig.SNOWPLOW_COLLECTOR_ENDPOINT || "";

export const excludedVanityNames: string[] =
  publicRuntimeConfig.EXCLUDED_VANITY_NAMES
    ? publicRuntimeConfig.EXCLUDED_VANITY_NAMES.split(",")
    : [
        "me",
        "following",
        "about-us",
        "aboutus",
        "privacy",
        "admin",
        "administrator",
        "curator",
        "editor",
        "play",
        "protect",
        "settings",
        "claim",
        "mobile",
        "today",
        "about",
        "people",
        "creation",
        "world",
        "nature",
        "spirit",
        "keys",
        "brands",
        "topprotectors",
        "naturereserves",
        "charities",
        "system",
      ];
export const namePageKeyTooltip: string =
  publicRuntimeConfig.NAMEPAGE_KEY_TOOLTIP || "Channel to discover";
export const namePageNatureTooltip: string =
  publicRuntimeConfig.NAMEPAGE_NATURE_TOOLTIP || "Channel to protect";
export const namePageSpiritTooltip: string =
  publicRuntimeConfig.NAMEPAGE_SPIRIT_TOOLTIP || "Channel to grow";
export const namePageWorldTooltip: string =
  publicRuntimeConfig.NAMEPAGE_WORLD_TOOLTIP || "Channel to inform";
export const namePageCreationTooltip: string =
  publicRuntimeConfig.NAMEPAGE_CREATION_TOOLTIP || "Channel to inspire";
export const namePagePeopleTooltip: string =
  publicRuntimeConfig.NAMEPAGE_PEOPLE_TOOLTIP || "Channel to connect";
export const apiKeyManual: string = publicRuntimeConfig.API_KEY_MANUAL || "";
export const cardInfoIFrameUrl: string =
  publicRuntimeConfig.CARD_INFO_IFRAME_URL ||
  "https://users-{userId}-cards-{cardId}.urbn8.com";
export const welcomeDomain: string =
  publicRuntimeConfig.WELCOME_DOMAIN || "uonearth.urbn8.io";
