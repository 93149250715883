/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable consistent-return */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/prefer-spread */

import {
  DeckDetailResponseDto,
  PromotionSeriesType,
} from "@earthtoday/contract";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
} from "mobx";
import getConfig from "next/config";
import Router from "next/router";
import { TFunction } from "next-i18next";
import { RefObject } from "react";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { OneFlowCreateOrgDriver } from "../../components/ModalCreateOrganization/OneFlowCreateOrgScreen";
import { ModalDonationDetailFormOneFlowDriver } from "../../components/ModalDonation/ModalDonationDetailForm";
import { OneFlowIdentityScreenDriver } from "../../components/ModalIdentity/OneFlowIdentityScreen";
import {
  ONE_FLOW_INDENTITY_AVATAR_HEIGHT,
  ONE_FLOW_INDENTITY_AVATAR_WIDTH,
} from "../../components/ModalIdentity/OneFlowIdentityScreen.styled";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import { OneFlowInitialState } from "../../components/ModalOneFlow/ModalOneFlow";
import {
  SocialProps,
  SocialUserFacebook,
} from "../../components/ModalSignUpSocialBtnFacebook/ModalSignUpSocialBtnFacebook";
import { ChooseM2Driver } from "../../components/OneFlowChooseM2Screen/OneFlowChooseM2Screen";
import { OneFlowGiftSubscriptionSuccessScreenDriver } from "../../components/OneFlowGiftSubscriptionSuccessScreen/OneFlowGiftSubscriptionSuccessScreen";
import { DiverOneFlowMissingEmailScreen } from "../../components/OneFlowMissingEmailScreen/OneFlowMissingEmailScreen";
import { OneFlowSignUpFormScreenDriver } from "../../components/OneFlowSignUpFormScreen/OneFlowSignUpFormScreen";
import { OneFlowSignUpDriver } from "../../components/OneFlowSignUpScreen/OneFlowSignUpScreen";
import { OneFlowStartScreenDriver } from "../../components/OneFlowStartScreen/OneFlowStartScreen";
import { ModalStartProtectDriver } from "../../components/OneFlowStartScreen/OneFlowStartScreenProtect";
import { OneFlowStartScreenSubscriptionDriver } from "../../components/OneFlowStartScreen/OneFlowStartScreenSubscription";
import {
  ErrorMessage,
  ITheMessageStore,
} from "../../components/TheMessage/TheMessageStore";
import { QrCodeOverlayPresenter } from "../../components/TheNavbarTop/TheNavbarStore/QrCodeOverlayPresenter";
import { TheNavbarStore } from "../../components/TheNavbarTop/TheNavbarStore/TheNavbarStore";
import { WhoRuScreenDriver } from "../../components/WhoRuScreen/WhoRuScreen";
import { DeckDetailApi } from "../../shared/apis/DeckDetailApi";
import { IGroupApi } from "../../shared/apis/GroupApi";
import { IPaymentApi, PaymentProvider } from "../../shared/apis/PaymentApi";
import { IProfileApi } from "../../shared/apis/ProfileApi";
import {
  TokenSwitchPayload,
  TokenType,
  UserProviderSocial,
  UserSessionApi,
  UserSocialSignInFlow,
} from "../../shared/apis/UserSessionApi";
import {
  CURRENT_YEAR,
  GIFT_AMOUNT_LIMIT,
  GROUP_VANITY_NAME_MAX_LENGTH,
  LIMIT_ORG_NAME,
  LIMIT_TEXT_NAME,
  m2Price,
  POSITIVE_INTEGER_REGEX,
  SUBSCRIBE_PRICE,
  SUBSCRIBE_UON_PER_CODE,
  SUBSCRIBE_UON_SIZE,
} from "../../shared/constants";
import {
  MIN_LENGTH,
  validateNameField,
  validateOrgNameField,
  validateUserEmail,
  validateUserPassword,
  ValidationCheck,
} from "../../shared/helpers/commonValidations";
import { copyLinkToClipboard } from "../../shared/helpers/copyLinkToClipboard";
import {
  detectSharingApp,
  SharingApp,
} from "../../shared/helpers/detectSharingApp";
import formatCurrency from "../../shared/helpers/formatCurrency";
import { getResizeImageUrl } from "../../shared/helpers/getResizeImageUrl";
import hashPassword from "../../shared/helpers/hashPassword";
import { isBrowser } from "../../shared/helpers/isBrowser";
import { translateAPIError } from "../../shared/helpers/translateApiError";
import { wait } from "../../shared/helpers/wait";
import { IntervalUnit } from "../../shared/models/Claim";
import { IUonCollectedData } from "../../shared/models/Events";
import { IGroupCreatePayload } from "../../shared/models/Group";
import { Logger } from "../../shared/models/Logger";
import {
  NewGroupPayload,
  OneFlowActAs,
  OneFlowDonationType,
  UserRegisterPayload,
} from "../../shared/models/OneFlow";
import {
  OneFlowSessionSource,
  PaymentMethod,
} from "../../shared/models/Payment";
import {
  GroupInfo,
  GroupProfileType,
  IMemberUserInfo,
  IUserFacebook,
  User,
  UserType,
} from "../../shared/models/User";
import {
  snowplowCaptureUonCollectedEvent,
  snowplowOneFlowPaymentStartedEvent,
} from "../../shared/snowplow";
import { AuthUserModel } from "../AuthUserModel";
import { CampaignDefaultResponse } from "../CampaignDefaultModel";
import {
  Bank,
  Currency,
  DonateScreen,
  DonateStore,
  UonSize,
  uonSizeOptions,
} from "../DonateStore/DonateStore";
import FeatureFlaggingStore from "../FeatureFlaggingStore";
import { GroupPresenter } from "../Group/GroupPresenter/GroupPresenter";
import { TypeErrorMessage } from "../ModalSignUpFormStore";
import { IModalStore, IOption, ModalType } from "../ModalStore";
import { ITokenStore } from "../TokenStore";
import { UserModel } from "../UserModel";

export enum OneFlowScreen {
  START = "start",
  WHO_ARE_YOU = "who_are_you",
  CREATE_ORG = "create_org",
  SIGN_UP = "sign_up",
  SIGN_UP_FORM = "sign_up_form",
  MISSING_EMAIL = "missing_email",
  CHOOSE_M2 = "choose_m2",
  IDENTITY = "identity",
  PAYMENT = "payment",
  CREATE_GROUP = "create_group",
  GIFT_SUBSCRIPTION_SUCCESS = "gift_subscription_success",
  PROTECT_SUCCESS = "protect_success",
  PERSONAL_SUBSCRIPTION_SUCCESS = "personal_subscription_success",
}

export interface OneFlowSignUp {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface OneFlowCreateOrg {
  email: string;
  orgName: string;
  firstName: string;
  lastName: string;
  password: string;
}

export enum OneFlowType {
  SUBSCRIBE = "SUBSCRIBE",
  PROTECT = "PROTECT",
}

export type IdentifyGroupDetail = Pick<
  User,
  "fullName" | "vanityName" | "image"
> & { group?: Pick<GroupInfo, "id"> };
export type IdentifyUserDetail = Pick<
  AuthUserModel,
  "fullName" | "imageData" | "vanityName" | "id"
>;

export enum CreateAccountType {
  INDIVIDUAL = "individual",
  ORGANIZATION = "organization",
}

export enum GiftSubscriptionShareOption {
  WHATSAPP = "WHATSAPP",
  TELEGRAM = "TELEGRAM",
  SMS = "SMS",
  MESSENGER = "MESSENGER",
  EMAIL = "EMAIL",
}

type IOneFlowInfo = {
  oneFlowType: OneFlowType | null;
  giftsCount: number | null;
};

export type IActAsOptions = IMemberUserInfo & {
  actAs: OneFlowActAs;
  dataTrackId: string;
};

export class ModalOneFlowPresenter
  implements
    OneFlowCreateOrgDriver,
    ModalDonationDetailFormOneFlowDriver,
    OneFlowIdentityScreenDriver,
    ChooseM2Driver,
    OneFlowGiftSubscriptionSuccessScreenDriver,
    DiverOneFlowMissingEmailScreen,
    OneFlowSignUpFormScreenDriver,
    OneFlowSignUpDriver,
    OneFlowStartScreenDriver,
    ModalStartProtectDriver,
    OneFlowStartScreenSubscriptionDriver,
    WhoRuScreenDriver
{
  private logger: Logger;
  @observable activatedScreens: IObservableArray<OneFlowScreen> =
    observable<OneFlowScreen>([]);
  @observable email: string = "";
  @observable socialInputEmail: string = "";
  @observable emailTouched: boolean = false;
  @observable orgName: string = "";
  @observable orgNameTouched: boolean = false;
  @observable orgDescription: string = "";
  @observable isConflictOrgName: boolean = false;
  @observable firstName: string = "";
  @observable firstNameTouched: boolean = false;
  @observable lastName: string = "";
  @observable lastNameTouched: boolean = false;
  @observable password: string = "";
  @observable passwordTouched: boolean = false;
  @observable acceptTerms: boolean = false;
  @observable submitting: boolean = false;
  @observable apiErrorMessage: TypeErrorMessage = null;
  @observable currentScreen: OneFlowScreen = OneFlowScreen.START;
  @observable hasGift: boolean = false;
  @observable giftsCount: number | null = null;
  @observable oneFlowType: OneFlowType | null = null;
  @observable errorMessage: string | null = null;
  @observable publishedGroups: IObservableArray<User> = observable<User>([]);
  @observable selectedGroup: IdentifyGroupDetail | null = null;
  @observable identityDropdownPlaceholder: string =
    "modal-oneflow.oneflow-identity-screen.dropdown-placeholder";
  @observable isShowingGroupsList: boolean = false;
  @observable selectedIdentity: string | undefined =
    this.userSessionStore.user?.id;
  @observable isUserSelected: boolean = true;
  @observable socialInputEmailTouched: boolean = false;
  @observable groupCreatePayload: IGroupCreatePayload | null = null;
  @observable isFetchingGroups: boolean = false;
  @observable isConfirmSubscribe: boolean = false;
  @observable size: UonSize = uonSizeOptions[1];
  @observable sizeOption: number = uonSizeOptions[1];
  @observable customSize: string = "";
  @observable method: PaymentMethod | null = null;
  @observable selectFormSubmitting: boolean = false;
  @observable selectMethodScreenError: ErrorMessage = null;
  @observable banks: Bank[] = [];
  @observable preSelectSize: UonSize | null = null;
  @observable invalidGiftAmount: boolean = false;
  @observable valueGiftInput: string = "";
  @observable shareId: string = "";
  @observable uonCount: string = "1"; // TODO: update this if needed in future
  @observable intervalUnit: IntervalUnit = "MONTHLY"; // TODO: update this if needed in future
  @observable subscrioptionLength: string = "1 year"; // TODO: update this if needed in future
  @observable numberOfUonProtected: string = "1"; // TODO: update this if needed in future
  @observable numberOfUonToBeProtected: string = "12"; // TODO: update this if needed in future
  @observable socialUser: SocialUserFacebook.Root | null = null;
  @observable groupImage: File | null = null;
  @observable sessionSource: OneFlowSessionSource | undefined = undefined; // TODO: set this after calling api sign up with social

  @observable createAccountType: CreateAccountType =
    CreateAccountType.INDIVIDUAL;
  @observable isTouched: boolean = false;
  @observable userFacebook: IUserFacebook | null = null;
  @observable isSkipWhoAreYouScreen: boolean = false;
  @observable isURLSpecific: boolean = false;
  @observable shareText: string =
    "modal-oneflow.personal-subscription-success-screen.share-text";
  @observable memberUserInfo: IMemberUserInfo | null = null;
  @observable actAs: OneFlowActAs | null = null;

  constructor(
    private initialState: OneFlowInitialState | null,
    private paymentAPI: IPaymentApi,
    private groupApi: IGroupApi,
    private modalStore: IModalStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private featureFlagStore: FeatureFlaggingStore,
    private profileApi: IProfileApi,
    private deckDetailApi: DeckDetailApi,
    private tokenStore: ITokenStore,
    private userSessionApi: UserSessionApi,
    private theNavbarStore: TheNavbarStore,
    private groupStore: GroupPresenter,
    public donateStore: DonateStore,
    logger: Logger,
  ) {
    makeObservable(this);
    this.logger = logger.child({ container: this.constructor.name });
    if (this.userSessionStore.user && this.isGroupToken) {
      const { user } = this.userSessionStore;
      this.selectedGroup = {
        group: user.group,
        vanityName: user.vanityName,
        fullName: user.fullName,
        image: user.userImage,
      };
      this.selectedIdentity = user.id;
      this.isUserSelected = false;
      this.identityDropdownPlaceholder = user.fullName;
    }

    if (this.initialState) {
      this.updateInitState(this.initialState);
    }

    //open oneFlow modal with finalize payment after redirect from Mollie
    if (this.donateStore.errorMollieResponse) {
      this.currentScreen = OneFlowScreen.PAYMENT;
      this.getOneFlowInfoLocalStorage();
      return;
    }

    if (this.donateStore.paymentResponse?.provider === PaymentProvider.MOLLIE) {
      switch (this.donateStore.oneFlowDonationTypeFromResponse) {
        case OneFlowDonationType.SUBSCRIBE_GIFT: {
          this.currentScreen = OneFlowScreen.GIFT_SUBSCRIPTION_SUCCESS;
          break;
        }
        case OneFlowDonationType.SUBSCRIBE_PERSONAL: {
          this.currentScreen = OneFlowScreen.PERSONAL_SUBSCRIPTION_SUCCESS;
          this.snowplowCaptureUonCollectedEvent();
          break;
        }
        case OneFlowDonationType.PROTECT: {
          this.currentScreen = OneFlowScreen.PROTECT_SUCCESS;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  @computed get emailFeedback(): ValidationCheck {
    const [valid, msg, { vars }] = validateUserEmail(this.email);

    if (!this.emailTouched) {
      return { valid, msg: "" };
    }

    if (msg === "modaljoin.email-valid") {
      return {
        valid: false,
        msg: "modaljoin.email-not-valid",
      };
    }

    if (msg === "modaljoin.min-length-characters") {
      return {
        valid: false,
        msg: "modaljoin.minimum-length",
        vars: {
          length: MIN_LENGTH,
        },
      };
    }

    return { valid, msg, vars };
  }
  @computed get currency(): Currency {
    return this.donateStore.currency;
  }

  @computed get socialInputEmailFeedback(): ValidationCheck {
    const [valid, msg, { vars }] = validateUserEmail(this.socialInputEmail);

    if (!this.socialInputEmailTouched) {
      return { valid, msg: "" };
    }

    if (this.apiErrorMessage) {
      return { valid: false, msg: "" };
    }

    return { valid, msg, vars };
  }
  @computed get firstNameFeedback(): ValidationCheck {
    const [valid, msg] = validateNameField(this.firstName, "First name");

    if (!this.firstNameTouched) {
      return { valid, msg: "" };
    }

    return { valid, msg };
  }
  @computed get lastNameFeedback(): ValidationCheck {
    const [valid, msg] = validateNameField(this.lastName, "Last name");

    if (!this.lastNameTouched) {
      return { valid, msg: "" };
    }

    return { valid, msg };
  }
  @computed get passwordFeedback(): ValidationCheck {
    const [valid, msg, { vars }] = validateUserPassword(this.password);

    if (!this.passwordTouched) {
      return { valid, msg: "" };
    }

    if (msg === "modaljoin.min-length-characters") {
      return {
        valid,
        msg: "modaljoin.minimum-length",
        vars,
      };
    }

    return { valid, msg, vars };
  }
  @computed get formValid(): boolean {
    if (this.apiErrorMessage) return false;

    return (
      this.emailFeedback.valid &&
      this.firstNameFeedback.valid &&
      this.lastNameFeedback.valid &&
      this.passwordFeedback.valid &&
      this.acceptTerms
    );
  }

  @computed get shouldRenderSubscribeM2Square(): boolean {
    return this.donateStore.promotionId ? false : true;
  }

  @action.bound updateSubmitting(b: boolean) {
    this.submitting = b;
  }
  @action.bound updateEmail(s: string) {
    if (this.apiErrorMessage === "This email address is already in use.") {
      this.apiErrorMessage = null;
    }

    this.email = s;
  }
  @action.bound updateSocialInputEmail(s: string) {
    if (this.apiErrorMessage === "This email address is already in use.") {
      this.apiErrorMessage = null;
    }

    this.socialInputEmail = s;
  }
  @action.bound updateEmailTouched(b: boolean) {
    this.emailTouched = b;
  }
  @action.bound updateOrgName(s: string): void {
    this.orgName = s.slice(0, LIMIT_ORG_NAME);
  }
  @action.bound updateOrgNameTouched(b: boolean) {
    this.orgNameTouched = b;
  }
  @action.bound updateConflictOrgName(b: boolean): void {
    this.isConflictOrgName = b;
  }
  @computed get orgNameFeedback(): ValidationCheck {
    const orgName = this.orgName.trim();
    const [valid, msg] = validateOrgNameField(this.orgName);

    if (!this.orgNameTouched) {
      return { valid, msg: "" };
    }

    if (this.isConflictOrgName) {
      return {
        valid: false,
        msg: "modal-oneflow.create-org-screen.org-name-in-use",
      };
    }

    if (orgName.length === 0) {
      return {
        valid: false,
        msg: "modal-oneflow.create-org-screen.org-name-required",
      };
    }

    return { valid, msg };
  }

  @computed get buildPayloadOrg(): OneFlowCreateOrg {
    return {
      orgName: this.orgName,
      lastName: this.lastName,
      firstName: this.firstName,
      email: this.email,
      password: this.password,
    };
  }

  @computed get payloadSignupEmail(): UserRegisterPayload | undefined {
    if (this.userSessionStore.user) {
      return;
    }
    return {
      emailAddress: this.email.toLowerCase(),
      firstName: this.firstName,
      lastName: this.lastName,
      preHashedPassword: hashPassword(this.password, this.email.toLowerCase()),
      acceptedTerms: true,
      ...this.userSessionStore.consentDataSignup(),
    };
  }

  @computed get enable2023MayCtaProtectAndSubscribeModal(): boolean {
    return this.featureFlagStore.flags.enable2023MayCtaProtectAndSubscribeModal;
  }

  @action.bound updateSocialInputEmailTouched(b: boolean) {
    this.socialInputEmailTouched = b;
  }
  @action.bound updateFirstName(s: string) {
    this.firstName = s.slice(0, LIMIT_TEXT_NAME);
  }
  @action.bound updateFirstNameTouched(b: boolean) {
    this.firstNameTouched = b;
  }
  @action.bound updateLastName(s: string) {
    this.lastName = s.slice(0, LIMIT_TEXT_NAME);
  }
  @action.bound updateLastNameTouched(b: boolean) {
    this.lastNameTouched = b;
  }
  @action.bound updatePassword(s: string) {
    this.password = s;
  }
  @action.bound updatePasswordTouched(b: boolean) {
    this.passwordTouched = b;
  }
  @action.bound updateAcceptTerms(b: boolean) {
    this.acceptTerms = b;
  }

  @computed get hasAcceptedSocialCookies(): boolean {
    return this.userSessionStore.hasAcceptedSocialCookies;
  }

  @computed get isSubscribeOrChooseM2Screen(): boolean {
    return (
      this.currentScreen === OneFlowScreen.CHOOSE_M2 ||
      (this.isConfirmSubscribe && this.currentScreen === OneFlowScreen.START)
    );
  }

  @computed get nextScreenWithSkipWhoAreYouScreen(): OneFlowScreen {
    if (this.createAccountType === CreateAccountType.ORGANIZATION) {
      return OneFlowScreen.CREATE_ORG;
    }
    return OneFlowScreen.SIGN_UP_FORM;
  }

  @computed get isGetGroupLoggedIn(): boolean {
    return (
      !!this.userSessionStore.user &&
      this.isGroupToken &&
      this.currentScreen === OneFlowScreen.IDENTITY
    );
  }

  @computed get isGetGroupOfUserLoggedIn(): boolean {
    return (
      this.currentScreen === OneFlowScreen.IDENTITY &&
      !!this.userSessionStore.user &&
      !this.isGroupToken
    );
  }

  @observable isLoaderVisible: boolean = false;

  @action.bound onMount = flow(function* onMount(this: ModalOneFlowPresenter) {
    if (!!this.donateStore.errorMollieResponse) {
      return;
    }

    if (this.currentScreen === OneFlowScreen.PAYMENT) {
      this.updatePaymentScreen();
      return;
    }

    if (!this.initialState?.redirectionConfig) {
      return;
    }

    const { redirectionConfig } = this.initialState;
    const { creditedEntity } = redirectionConfig;

    if (
      redirectionConfig.redirectMode === "onModalOpen" &&
      redirectionConfig.redirectPath
    ) {
      Router.push(redirectionConfig.redirectPath, undefined, {
        shallow: true,
      });
    }

    if (creditedEntity.type === "GLOBAL") {
      return;
    }

    try {
      this.isLoaderVisible = true;

      if (creditedEntity.type === "PROFILE") {
        const userProfile = yield* toFlowGeneratorFunction(
          this.profileApi.fetchProfileDetail,
        )(creditedEntity.vanityName);

        const campaignId = userProfile.defaultCampaign.id;

        // donate to charity group's campaign
        if (
          userProfile.details?.type === UserType.GROUP_ADAPTER &&
          userProfile.group?.profileType === GroupProfileType.CHARITY
        ) {
          this.donateStore.campaignSource = null;
          this.donateStore.charityProfile = {
            name: userProfile.fullName,
            id: userProfile.id,
          };
          return;
        }

        // donate to NPO's reserves
        if (
          userProfile.details?.type === UserType.GROUP_ADAPTER &&
          userProfile.group?.profileType === GroupProfileType.NPO
        ) {
          this.donateStore.campaignSource = null;

          if (userProfile.isDisabledProtect) {
            this.donateStore.updateNpoId("");
          } else {
            this.donateStore.updateNpoId(userProfile.id);
          }
          return;
        }

        if (!campaignId) return;

        this.donateStore.campaignSource = userProfile.isDisabledProtect
          ? null
          : { fullName: userProfile.fullName, campaignId };
        return;
      }

      if (creditedEntity.type === "RESERVE") {
        const [deckDetail, userProfile]: [
          DeckDetailResponseDto,
          User & {
            defaultCampaign: CampaignDefaultResponse;
          },
        ] = yield Promise.all([
          this.deckDetailApi.fetchDeckDetail(
            creditedEntity.vanityName,
            creditedEntity.deckLinkName,
          ),
          this.profileApi.fetchProfileDetail(creditedEntity.vanityName),
        ]);

        const reserveId: string = (() => {
          if (deckDetail.data.reserves && deckDetail.data.reserves[0]) {
            const id = deckDetail.data.reserves[0].id.toString();
            return id;
          }
          return "";
        })();

        if (reserveId && !userProfile.isDisabledProtect) {
          this.donateStore.updateReserveId(reserveId);
          return;
        }

        this.donateStore.updateReserveId("");
      }
    } finally {
      this.isLoaderVisible = false;
    }
  });

  @action.bound onM2AmountSelected = flow(function* (
    this: ModalOneFlowPresenter,
    amount: UonSize,
  ) {
    this.updateUonSize(amount);
    this.updateOneFlowType(OneFlowType.PROTECT);
    yield* toFlowGeneratorFunction(this.onStartScreenContinueBtnClicked)();
  });

  @action.bound onM2AmountNextBtnClicked() {
    this.updateUonSize("custom");
    this.updateOneFlowType(OneFlowType.PROTECT);
    this.onStartScreenContinueBtnClicked();
  }

  @action.bound getMemberUserInfo = flow(function* getMemberUserInfo(
    this: ModalOneFlowPresenter,
  ) {
    if (!this.isGroupToken) return;

    const memberUserId = this.tokenStore.getMemberUserId();
    const memberData = yield this.profileApi.fetchProfileByUserId(memberUserId);
    this.memberUserInfo = {
      fullName: memberData.fullName,
      photo: getResizeImageUrl(memberData.image, {
        width: ONE_FLOW_INDENTITY_AVATAR_WIDTH,
        height: ONE_FLOW_INDENTITY_AVATAR_HEIGHT,
      }),
    };
  });

  @action.bound updateCurrentScreen = flow(function* updateCurrentScreen(
    this: ModalOneFlowPresenter,
    screen: OneFlowScreen,
  ) {
    if (this.stripeCardSubmitting) return;
    this.activatedScreens.push(this.currentScreen);
    this.currentScreen =
      this.isSkipWhoAreYouScreen &&
      this.isSubscribeOrChooseM2Screen &&
      !this.loggedInUser
        ? this.nextScreenWithSkipWhoAreYouScreen
        : screen;
    if (!!this.userSessionStore.user && this.isGetGroupLoggedIn) {
      const { user } = this.userSessionStore;
      this.selectedGroup = {
        group: user.group,
        vanityName: user.vanityName,
        fullName: user.fullName,
        image: user.userImage,
      };
      this.selectedIdentity = user.id;
      this.isUserSelected = false;
      this.identityDropdownPlaceholder = user.fullName;
    }

    if (!!this.userSessionStore.user && this.isGetGroupOfUserLoggedIn) {
      try {
        this.isFetchingGroups = true;
        const res = yield this.groupApi.getJoinedGroups(
          this.userSessionStore.user.id,
          true,
        );
        if (res.length > 0) {
          this.publishedGroups.replace(res);
        }
      } catch (error) {
        this.errorMessage = translateAPIError(error);
      } finally {
        this.isFetchingGroups = false;
      }
    }
    if (this.currentScreen === OneFlowScreen.PAYMENT) {
      yield this.donateStore.fetchPaymentMethods();
    }
  });

  @action.bound updateHasGift(): void {
    this.hasGift = !this.hasGift;
  }

  @action.bound updateGiftsCount(value: string): void {
    this.invalidGiftAmount = false;

    if (value === "0") {
      this.valueGiftInput = "";
      return;
    }

    this.valueGiftInput = value;
    if (!POSITIVE_INTEGER_REGEX.test(value)) {
      this.invalidGiftAmount = true;
      this.giftsCount = null;
      return;
    }

    if (+value > GIFT_AMOUNT_LIMIT) {
      this.giftsCount = GIFT_AMOUNT_LIMIT;
      this.valueGiftInput = `${GIFT_AMOUNT_LIMIT}`;
      return;
    }

    this.giftsCount = +value;
  }

  @action.bound updateOneFlowType(type: OneFlowType): void {
    this.oneFlowType = type;
  }

  @action.bound openModal(modalType: ModalType, options?: IOption): void {
    this.modalStore.openModal(modalType, options);
  }

  @action.bound showErrorMessage(
    errorMessage: string,
    title = "toast-message.general.error",
  ): void {
    this.theMessageStore.showMessage({
      typeMessage: "Error",
      title,
      content: errorMessage,
    });
  }

  @action.bound onFacebookBtnClick(socialProps: SocialProps | null): void {
    if (!this.hasAcceptedSocialCookies) {
      this.openModal("loginSocial");
      return;
    }

    this.submitting = true;

    // eslint-disable-next-line no-unused-expressions
    socialProps
      ? socialProps.triggerLogin()
      : (this.errorMessage = translateAPIError({
          name: "error",
          message: "Social cookies haven't been accepted.",
        }));
  }

  @action.bound onFacebookFailure(err: Error): void {
    this.logger.error({ err }, "facebook login fail");
    this.submitting = false;
    this.errorMessage = translateAPIError(err);
  }

  @action.bound onFacebookSuccess = flow(function* (
    this: ModalOneFlowPresenter,
    socialUser: SocialUserFacebook.Root,
  ) {
    this.logger.info("socialUser", socialUser);
    this.submitting = true;
    try {
      yield this.loginWithFacebook(socialUser);
    } catch (error) {
      this.errorMessage = translateAPIError(error);
    } finally {
      this.submitting = false;
    }
  });

  @action.bound toProtectOnceClick(): void {
    this.updateConfirmSubscribe(false);
    this.oneFlowType = OneFlowType.PROTECT;
    this.updateCurrentScreen(OneFlowScreen.CHOOSE_M2);
  }

  @action.bound onGoogleBtnClick(socialProps: SocialProps | undefined): void {
    if (!this.hasAcceptedSocialCookies) {
      this.openModal("loginSocial");
      return;
    }

    this.submitting = true;

    if (!socialProps) {
      this.errorMessage = translateAPIError({
        name: "error",
        message: "Social cookies haven't been accepted.",
      });
      return;
    }

    socialProps.triggerLogin();
  }
  @action.bound onGoogleFailure(err: Error): void {
    this.logger.info({ err }, "google login fail");
    this.submitting = false;
    this.errorMessage = translateAPIError(err);
  }
  @action.bound onGoogleSuccess = flow(function* (
    this: ModalOneFlowPresenter,
    googleToken: string,
  ) {
    this.logger.info("googleToken", googleToken);
    this.submitting = true;
    try {
      yield this.loginWithGoogle(googleToken);
      this.theNavbarStore.onMount();
    } catch (error) {
      this.errorMessage = translateAPIError(error);
    } finally {
      this.submitting = false;
    }
  });

  @action.bound onRegister = flow(function* onRegister(
    this: ModalOneFlowPresenter,
    payl: OneFlowSignUp,
  ) {
    const info = {
      emailAddress: payl.email.toLowerCase(),
      firstName: payl.firstName,
      lastName: payl.lastName,
      preHashedPassword: hashPassword(payl.password, payl.email.toLowerCase()),
      ...this.userSessionStore.consentDataSignup(),
    };

    yield this.userSessionApi.register(info);
    yield this.userSessionStore.onAcceptTerm();

    this.theMessageStore.showMessage(
      {
        typeMessage: "Close",
        title: "Email Verification",
        content: "toast-message.email-verification.content-update",
      },
      {
        closeDuration: "never",
      },
    );

    this.openModal("");
  });

  //numberOfUon
  @computed get uonSizePayload(): number {
    if (this.oneFlowType === OneFlowType.SUBSCRIBE) {
      return this.uonSizeSubscribe;
    }

    return this.donateSize;
  }

  @action.bound onSubmitRegister = flow(function* onSubmitRegister(
    this: ModalOneFlowPresenter,
  ) {
    try {
      this.submitting = true;
      this.errorMessage = null;
      this.apiErrorMessage = null;

      if (!this.formValid) {
        return;
      }

      const result = yield this.userSessionStore.checkIfExistingEmailAddress({
        emailAddress: this.email,
      });

      if (result.isUsed) {
        throw "This email address is already in use.";
      }

      yield this.donateStore.prepareAccountOneFlow(
        {
          type: this.oneFlowType || OneFlowType.PROTECT,
          actAs: this.payloadNewGroup ? OneFlowActAs.GROUP : this.payloadActAs,
          signupEmail: this.payloadSignupEmail,
          newGroup: this.payloadNewGroup,
          sessionSource: this.sessionSource,
        },
        null, //signup and create org not upload image of group
      );
      this.updatePaymentScreen();
    } catch (error) {
      this.errorMessage = translateAPIError(error);
      this.apiErrorMessage = translateAPIError(error);
    } finally {
      this.submitting = false;
    }
  });

  @action.bound loginWithFacebook = flow(function* loginWithFacebook(
    this: ModalOneFlowPresenter,
    socialUser: SocialUserFacebook.Root,
  ) {
    this.socialUser = socialUser;
    this.userFacebook = yield this.userSessionApi.getUserSocialFacebook(
      socialUser,
    );

    if (this.userFacebook && !this.userFacebook.email) {
      const userSocialInfo = yield this.userSessionApi.getUserSocialInfo(
        UserProviderSocial.FACEBOOK,
        this.userFacebook.id,
      );

      if (!userSocialInfo.hasEmailAddress) {
        this.updateCurrentScreen(OneFlowScreen.MISSING_EMAIL);
        return;
      }
    }

    let user = yield this.userSessionApi.facebookConnect(
      socialUser,
      this.userSessionStore.consentDataSignup(),
      this.userFacebook?.email
        ? { flow: UserSocialSignInFlow.ONEFLOW }
        : undefined,
    );

    if (!user.termsAccepted) {
      user = yield this.userSessionApi.acceptTerm();
    }

    yield this.userSessionStore.setUser(user);
    this.theNavbarStore.onMount();

    if (!!user.isNewUser) {
      this.sessionSource = OneFlowSessionSource.SIGNUP_SOCIAL;
    }

    this.updateCurrentScreen(OneFlowScreen.PAYMENT);
    yield this.donateStore.prepareAccountOneFlow(
      {
        type: this.oneFlowType || OneFlowType.PROTECT,
        actAs: this.payloadActAs,
        signupEmail: this.payloadSignupEmail,
        newGroup: this.payloadNewGroup,
        sessionSource: this.sessionSource,
      },
      null, //signup and create org not upload image of group
    );
  });

  @action.bound loginWithGoogle = flow(function* loginWithGoogle(
    this: ModalOneFlowPresenter,
    token: string,
  ) {
    let user = yield this.userSessionApi.googleConnect(
      token,
      this.userSessionStore.consentDataSignup(),
      UserSocialSignInFlow.ONEFLOW,
    );

    if (user && !user.termsAccepted) {
      user = yield this.userSessionApi.acceptTerm();
    }

    yield this.userSessionStore.setUser(user);
    if (!!user.isNewUser) {
      this.sessionSource = OneFlowSessionSource.SIGNUP_SOCIAL;
    }

    this.updateCurrentScreen(OneFlowScreen.PAYMENT);
    yield this.donateStore.prepareAccountOneFlow(
      {
        type: this.oneFlowType || OneFlowType.PROTECT,
        actAs: this.payloadActAs,
        signupEmail: this.payloadSignupEmail,
        newGroup: this.payloadNewGroup,
        sessionSource: this.sessionSource,
      },
      null, //signup and create org not upload image of group
    );
  });

  @action.bound goBack(): void {
    if (this.currentScreen === OneFlowScreen.CREATE_GROUP) {
      this.groupCreatePayload = null;
      this.groupStore.resetForm();
    }
    if (this.activatedScreens.length === 0) {
      this.openModal("");
      return;
    }

    const previousScreen = this.activatedScreens.pop() || OneFlowScreen.START;
    this.currentScreen = previousScreen;
  }

  @computed get loggedInUser(): IdentifyUserDetail | null {
    return this.userSessionStore?.user;
  }
  @computed get loggedInUserVanityname(): string {
    return this.userSessionStore.user?.vanityName || "";
  }
  @computed get isGroupToken(): boolean {
    return this.userSessionStore.isGroupToken;
  }
  @computed get isDisableDropdown(): boolean {
    return this.publishedGroups.length === 0;
  }

  @action.bound updateSelectedGroup(group: IdentifyGroupDetail): void {
    this.selectedGroup = group;
    this.identityDropdownPlaceholder = group.fullName;
    this.updateSelectedIdentity(group.group?.id || "");
  }
  @action.bound toggleShowGroupsList(): void {
    this.isShowingGroupsList = !this.isShowingGroupsList;
  }
  @action.bound updateSelectedIdentity(id: string): void {
    this.selectedIdentity = id;
    this.isUserSelected = this.selectedIdentity === this.loggedInUser?.id;
  }
  @action.bound switchTokenGroup = flow(function* (
    this: ModalOneFlowPresenter,
  ) {
    const payload: TokenSwitchPayload = {
      targetType: TokenType.GROUP,
      refreshToken: this.tokenStore.getRefreshToken() || "",
      groupId: this.selectedIdentity,
    };

    yield this.userSessionApi.switchToken(payload);
    const user: User = yield this.userSessionApi.getMe();
    this.userSessionStore.setUser(user);
  });

  @action.bound switchTokenUser = flow(function* (this: ModalOneFlowPresenter) {
    const payload: TokenSwitchPayload = {
      targetType: TokenType.USER,
      refreshToken: this.tokenStore.getRefreshToken(),
    };

    yield this.userSessionStore.switchToken(payload);
  });

  @computed get groupNameFeedback(): ValidationCheck {
    const { groupNameFeedback } = this.groupStore;

    if (groupNameFeedback.msg === "groups.name-required") {
      return {
        valid: false,
        msg: "groups.oneflow-name-required",
      };
    }
    if (groupNameFeedback.msg === "groups.already-in-used") {
      return {
        valid: false,
        msg: "groups.oneflow-already-in-used",
      };
    }

    return groupNameFeedback;
  }

  @computed get areFormFieldsDisabled(): boolean {
    return this.groupStore.areFormFieldsDisabled;
  }

  @computed get groupVanityNameFeedback(): ValidationCheck {
    const { groupVanityNameFeedback } = this.groupStore;
    if (
      groupVanityNameFeedback.msg === "groups.vanity-length" ||
      groupVanityNameFeedback.msg === "groups.vanity-required"
    ) {
      return {
        valid: false,
        msg: "groups.oneflow-vanity-length",
        vars: {
          length: GROUP_VANITY_NAME_MAX_LENGTH,
        },
      };
    }
    if (groupVanityNameFeedback.msg === "groups.already-in-used") {
      return {
        valid: false,
        msg: "groups.oneflow-already-in-used",
      };
    }
    if (groupVanityNameFeedback.msg === "groups.can-not-be-used") {
      return {
        valid: false,
        msg: "groups.oneflow-can-not-be-used",
      };
    }

    return groupVanityNameFeedback;
  }
  @computed get recommendVanityNames(): string[] {
    return this.groupStore.recommendVanityNames;
  }
  @computed get groupImagePath(): string {
    return this.groupStore.groupImagePath;
  }
  @computed get groupName(): string {
    return this.groupStore.groupName;
  }
  @computed get groupVanityName(): string {
    return this.groupStore.groupVanityName;
  }
  @computed get groupDescription(): string {
    return this.groupStore.groupDescription;
  }
  @computed get canSubmit(): boolean {
    return this.groupStore.canSubmit;
  }
  @action.bound updateGroupVanityName = (s: string) => {
    this.groupStore.updateGroupVanityName(s);
  };
  @action.bound updateGroupVanityNameTouched = (b: boolean) => {
    this.groupStore.updateGroupVanityNameTouched(b);
  };
  @action.bound updateGroupImage = (inputRef: RefObject<HTMLInputElement>) => {
    this.groupStore.updateGroupImage(inputRef);
  };
  @action.bound updateGroupNameTouched = (b: boolean) => {
    this.groupStore.updateGroupNameTouched(b);
  };
  @action.bound updateGroupName = (s: string) => {
    this.groupStore.updateGroupName(s);
  };
  @action.bound updateIsConflictGroupName = (b: boolean) => {
    this.groupStore.updateIsConflictGroupName(b);
  };
  @action.bound updateGroupDescription = (s: string) => {
    this.groupStore.updateGroupDescription(s);
  };
  @action.bound updateGroupDescriptionTouched = (b: boolean) => {
    this.groupStore.updateGroupDescriptionTouched(b);
  };
  @action.bound onSaveDataForCreateGroup = (
    inputRef: RefObject<HTMLInputElement>,
  ) => {
    this.groupCreatePayload = {
      name: this.groupName,
      vanityName: this.groupVanityName,
      description: this.groupDescription,
    };
    const file = this.groupStore.onSelectImage(inputRef);
    if (!file) {
      return;
    }
    this.groupImage = file;
  };
  @action.bound onSubmit = () => {};
  @action.bound updateCreateAccountType(type: CreateAccountType): void {
    this.createAccountType = type;
  }

  @computed get selectFormSubmitBtnEnabled(): boolean {
    if (this.selectFormSubmitting) {
      return false;
    }
    return !!this.donateSize; // not enabled case size = 0
  }

  @computed get renderUonSizeOptions(): number[] {
    const index = this.size
      ? uonSizeOptions.indexOf(this.sizeOption)
      : uonSizeOptions.indexOf(this.sizeOption);
    if (index === uonSizeOptions.length - 2) {
      return uonSizeOptions.slice(index).concat(uonSizeOptions.slice(0, 1));
    }
    if (index === uonSizeOptions.length - 1) {
      return uonSizeOptions.slice(index).concat(uonSizeOptions.slice(0, 2));
    }
    if (!uonSizeOptions.includes(this.sizeOption)) {
      return uonSizeOptions.slice(0, 3);
    }
    return uonSizeOptions.slice(index, index + 3);
  }

  @computed get donateSize(): number {
    if (this.size === "custom") {
      return +this.customSize;
    }

    return this.size;
  }

  @computed get donateTotal(): number {
    return this.donateSize * m2Price;
  }

  @computed get isOptionSizeDisabled(): boolean {
    return !!this.preSelectSize;
  }

  @computed get isPreparingAccount(): boolean {
    return this.donateStore.isPreparingAccount;
  }

  @action.bound updateUonSize(size: UonSize): void {
    this.size = size;
    if (this.size !== "custom" && !this.isOptionSizeDisabled) {
      this.sizeOption = Number.parseFloat(`${size}`);
    }
  }

  @action preSelectUonOption = (uon: number): void => {
    this.updateUonSize(uon);
    this.preSelectSize = uon;
  };

  @action.bound updateUonCustomSize(size: string): void {
    this.customSize = size;
  }

  getBankList = flow(function* getBankList(this: ModalOneFlowPresenter) {
    this.selectMethodScreenError = null;
    const api = this.paymentAPI;
    try {
      this.banks = yield api.getListPaymentMollie();
    } catch (error) {
      this.selectMethodScreenError = translateAPIError(error);
    }
  });

  @action donateEuro = (size: number): number => {
    return size * m2Price;
  };

  @action.bound submitPayDetails(): void {
    this.selectMethodScreenError = null;
    if (!this.loggedInUser) {
      if (!this.featureFlagStore.flags.enableLoginWithQRCode) {
        this.currentScreen = OneFlowScreen.SIGN_UP;
      }

      return;
    }

    this.currentScreen = OneFlowScreen.IDENTITY;
  }

  @action.bound updateConfirmSubscribe(b: boolean): void {
    this.isConfirmSubscribe = b;
  }

  @computed get subscribePay(): string {
    if (!this.giftsCount || !this.hasGift) {
      return formatCurrency(SUBSCRIBE_PRICE);
    }
    return formatCurrency(SUBSCRIBE_PRICE * this.giftsCount);
  }
  @computed get uonSizeSubscribe(): number {
    if (!this.giftsCount) {
      return SUBSCRIBE_UON_SIZE;
    }
    return this.giftsCount * SUBSCRIBE_UON_SIZE;
  }

  @computed get subscribePrice(): number {
    if (!this.giftsCount || !this.hasGift) {
      return SUBSCRIBE_PRICE;
    }
    return SUBSCRIBE_PRICE * this.giftsCount;
  }

  @computed get userFullName(): string {
    return this.userSessionStore.user?.fullName || "";
  }

  @computed get pathSharing(): string {
    return `${getConfig().publicRuntimeConfig.REACT_APP_HOST}/share/subscribe/${
      this.donateStore.oneFlowShareId
    }`;
  }

  get shareWithMessengerRedirectPath(): string {
    return getConfig().publicRuntimeConfig.REACT_APP_HOST;
  }

  @computed get stripeCardSubmitting(): boolean {
    return this.donateStore.stripeCardSubmitting;
  }

  @computed get viewPersonalSubscription(): string {
    return `/${this.loggedInUserVanityname}?tab=${
      this.featureFlagStore.flags.enableNewProfilePage
        ? "wallet"
        : "your-square-meters"
    }`;
  }

  @action.bound detectSharingApp(
    t: TFunction,
    sharingApp: SharingApp,
    pathSharing?: string,
  ) {
    return detectSharingApp(
      t(this.shareText),
      pathSharing || this.pathSharing,
      sharingApp,
    );
  }

  @action.bound copyCardLinkToClipboard = flow(
    function* copyCardLinkToClipboard(
      this: ModalOneFlowPresenter,
      cardUrl: string,
    ) {
      copyLinkToClipboard(cardUrl);
      yield wait(200);
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.link-copied.title",
        content: "toast-message.link-copied.content",
      });
    },
  );

  @computed get payloadNewGroup(): NewGroupPayload | undefined {
    if (this.groupCreatePayload) {
      return this.groupCreatePayload;
    }
    if (this.orgName) {
      return {
        name: this.orgName,
      };
    }
  }

  @computed get payloadActAs(): OneFlowActAs {
    if (this.userSessionStore.user) {
      return this.isGroupToken ? OneFlowActAs.GROUP : OneFlowActAs.USER;
    }
    if (this.createAccountType === CreateAccountType.INDIVIDUAL) {
      return OneFlowActAs.USER;
    }
    return OneFlowActAs.GROUP;
  }

  @action.bound onSubmitStripeCardOneFlow = flow(
    function* onSubmitStripeCardOneFlow(
      this: ModalOneFlowPresenter,
      stripe: Stripe,
      elements: StripeElements,
    ) {
      yield this.donateStore.submitStripeCardFormOneFlow(stripe, elements, {
        type: this.oneFlowType || OneFlowType.PROTECT,
        giftsCount:
          this.oneFlowType === OneFlowType.SUBSCRIBE && this.hasGift
            ? this.giftsCount || 1
            : undefined,
      });

      if (!this.donateStore.paymentResponse) return; // TODO: show error
      if (
        this.donateStore.oneFlowDonationTypeFromResponse ===
        OneFlowDonationType.PROTECT
      ) {
        this.updateCurrentScreen(OneFlowScreen.PROTECT_SUCCESS);
        return;
      }
      if (
        this.donateStore.oneFlowDonationTypeFromResponse ===
        OneFlowDonationType.SUBSCRIBE_GIFT
      ) {
        this.updateCurrentScreen(OneFlowScreen.GIFT_SUBSCRIPTION_SUCCESS);
        return;
      }
      this.updateCurrentScreen(OneFlowScreen.PERSONAL_SUBSCRIPTION_SUCCESS);
      this.snowplowCaptureUonCollectedEvent();
    },
  );

  @action.bound onSubmitIdealPaymentOneFlow = flow(
    function* onSubmitIdealPaymentOneFlow(this: ModalOneFlowPresenter) {
      this.groupStore.resetForm();
      const existingGroupId = this.userSessionStore.user?.group?.id;
      this.saveOneFlowInfoLocalStorage();
      yield this.donateStore.submitIdealPaymentOneFlow(
        {
          type: this.oneFlowType || OneFlowType.PROTECT,
          actAs: this.payloadNewGroup ? OneFlowActAs.GROUP : this.payloadActAs,
          giftsCount:
            this.oneFlowType === OneFlowType.SUBSCRIBE && this.hasGift
              ? this.giftsCount || 1
              : undefined,
          signupEmail: this.payloadSignupEmail,
          newGroup: this.payloadNewGroup,
          existingGroupId,
          sessionSource: this.sessionSource,
          redirectionConfig: this.initialState?.redirectionConfig,
        },
        this.groupImage,
      );
    },
  );

  @action.bound updatePaymentScreen(): void {
    this.updateCurrentScreen(OneFlowScreen.PAYMENT);
    this.donateStore.updateScreen(DonateScreen.PaymentDetails, false);
    this.donateStore.updateUonSize(this.uonSizePayload);
    snowplowOneFlowPaymentStartedEvent({
      triggerPointType: this.triggerPointType,
      triggerPoint: this.triggerPoint,
      oneFlowType: this.oneFlowType || OneFlowType.PROTECT,
      giftCount: this.giftsCount || 0,
      totalUonValue: this.uonSizePayload,
      totalPrice: +(this.uonSizePayload * m2Price).toFixed(2),
    });
  }
  @action.bound updateInitState(data: {
    oneFlowType: OneFlowType | null;
    currentScreen: OneFlowScreen;
  }) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      this[key] = data[key];
    }
    if (!this.enable2023MayCtaProtectAndSubscribeModal) {
      this.updateConfirmSubscribe(true);
    }
  }

  @action.bound completeUpdateEmail = flow(function* completeUpdateEmail(
    this: ModalOneFlowPresenter,
  ) {
    try {
      this.submitting = true;
      this.errorMessage = null;
      this.apiErrorMessage = null;

      if (!this.socialInputEmail) {
        return;
      }

      const result = yield this.userSessionStore.checkIfExistingEmailAddress({
        emailAddress: this.socialInputEmail,
      });

      if (result.isUsed) {
        throw "This email address is already in use.";
      }

      if (!this.socialUser) {
        throw new Error("Social User is invalid.");
      }
      let user = yield this.userSessionApi.facebookConnect(
        this.socialUser,
        this.userSessionStore.consentDataSignup(),
        {
          flow: UserSocialSignInFlow.ONEFLOW,
          emailAddress: this.socialInputEmail,
        },
      );

      if (!user.termsAccepted) {
        user = yield this.userSessionApi.acceptTerm();
      }

      yield this.userSessionStore.setUser(user);
      this.theNavbarStore.onMount();

      if (!!user.isNewUser) {
        this.sessionSource = OneFlowSessionSource.SIGNUP_SOCIAL;
      }

      this.updateCurrentScreen(OneFlowScreen.IDENTITY);
    } catch (error) {
      this.errorMessage = translateAPIError(error);
      this.apiErrorMessage = translateAPIError(error);
    } finally {
      this.submitting = false;
    }
  });
  @computed get emailSharingSubject(): {
    msg: string;
    vars: { currentYear: number };
  } {
    return {
      msg: "modal-oneflow.personal-subscription-success-screen.email-subject",
      vars: {
        currentYear: CURRENT_YEAR,
      },
    };
  }
  @action.bound getEmailSharingBody(pathSharing?: string): {
    msg: string;
    vars: { currentYear: number; pathSharing: string };
  } {
    if (pathSharing) {
      return {
        msg: "modal-oneflow.personal-subscription-success-screen.email-body",
        vars: {
          currentYear: CURRENT_YEAR,
          pathSharing,
        },
      };
    }

    return {
      msg: "modal-oneflow.personal-subscription-success-screen.email-body",
      vars: {
        currentYear: CURRENT_YEAR,
        pathSharing: this.pathSharing,
      },
    };
  }

  @action.bound switchToken = flow(function* (this: ModalOneFlowPresenter) {
    if (this.isGroupToken) {
      if (this.actAs === OneFlowActAs.GROUP) {
        return;
      }

      yield this.switchTokenUser();
      return;
    }

    if (!this.selectedGroup) {
      return;
    }

    yield this.switchTokenGroup();
  });

  @action.bound submitIdentity = flow(function* submitIdentity(
    this: ModalOneFlowPresenter,
  ) {
    yield this.switchToken();

    this.updatePaymentScreen();

    const existingGroupId = this.userSessionStore.user?.group?.id;
    yield this.donateStore.prepareAccountOneFlow(
      {
        type: this.oneFlowType || OneFlowType.PROTECT,
        actAs: this.payloadNewGroup ? OneFlowActAs.GROUP : this.payloadActAs,
        signupEmail: this.payloadSignupEmail,
        newGroup: this.payloadNewGroup,
        existingGroupId,
        sessionSource: this.sessionSource,
      },
      this.groupImage,
    );
  });

  @computed get userHasEmail(): boolean {
    if (this.isGroupToken) return true; //email of group is email of owner user
    return !!this.userSessionStore.user?.emailAddress;
  }

  @computed get oneFlowInfoKey(): string {
    return `oneFlowInfo_${this.userSessionStore.user?.vanityName}`;
  }

  @action.bound saveOneFlowInfoLocalStorage(): void {
    if (!isBrowser()) return;
    const saveItem = JSON.stringify({
      oneFlowType: this.oneFlowType,
      giftsCount: this.giftsCount,
    });
    window.localStorage.setItem(this.oneFlowInfoKey, saveItem);
  }

  @action.bound getOneFlowInfoLocalStorage(): void {
    if (!isBrowser()) return;
    const getItem = window.localStorage.getItem(this.oneFlowInfoKey);
    if (getItem) {
      const oneFlowInfo: IOneFlowInfo = JSON.parse(getItem);
      this.oneFlowType = oneFlowInfo.oneFlowType;
      this.giftsCount = oneFlowInfo.giftsCount;
    }
  }

  @action.bound removeOneFlowInfoLocalStorage(): void {
    if (!isBrowser()) return;
    window.localStorage.removeItem(this.oneFlowInfoKey);
  }

  @action.bound getSMSUri(
    t: TFunction,
    shareText?: string,
    pathSharing?: string,
  ): string {
    if (/android/i.test(navigator.userAgent)) {
      return `sms:?body=${t(shareText || this.shareText)}%0A${
        pathSharing || this.pathSharing
      }`;
    }
    return `sms:&body=${t(shareText || this.shareText)}%0A${
      pathSharing || this.pathSharing
    }`;
  }

  @action.bound closeModal(): void {
    this.actAs = null;
    this.modalStore.openModal("");
    if (this.currentScreen === OneFlowScreen.MISSING_EMAIL) {
      Router.push("/", undefined, { shallow: true });
    }
    this.donateStore.resetOnClickOutSide();
    this.donateStore.resetParams({ isResetParamsURLSpecific: true });
    this.removeOneFlowInfoLocalStorage();
  }

  @action.bound onViewPersonalSubscriptionBtnClicked(): void {
    this.closeModal();
  }

  @action.bound onCloseBtnClicked(): void {
    this.closeModal();

    const isSuccessScreen =
      this.currentScreen === OneFlowScreen.PROTECT_SUCCESS ||
      this.currentScreen === OneFlowScreen.GIFT_SUBSCRIPTION_SUCCESS ||
      this.currentScreen === OneFlowScreen.PERSONAL_SUBSCRIPTION_SUCCESS;

    if (
      isSuccessScreen &&
      this.initialState?.redirectionConfig?.redirectMode ===
        "onPaymentSuccessAndClose" &&
      this.initialState?.redirectionConfig?.redirectPath
    ) {
      const redirectPath = decodeURIComponent(
        this.initialState.redirectionConfig.redirectPath,
      );
      Router.push(redirectPath, undefined, {
        shallow: true,
      });
    }
  }

  @action.bound updateShareText(
    shareOption: GiftSubscriptionShareOption,
  ): void {
    this.shareText =
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      shareOption === GiftSubscriptionShareOption.TELEGRAM
        ? "modal-oneflow.personal-subscription-success-screen.share-text-telegram-mobile"
        : "modal-oneflow.personal-subscription-success-screen.share-text";
  }

  @computed get closeButtonTrackId(): string | undefined {
    if (this.isConfirmSubscribe && this.currentScreen === OneFlowScreen.START) {
      return "oneFlow_modal-subscribe_btn-close";
    }

    switch (this.currentScreen) {
      case OneFlowScreen.WHO_ARE_YOU: {
        return "oneFlow_modal-whoAmI_btn-close";
      }
      case OneFlowScreen.SIGN_UP: {
        return "oneFlow_modal-signUp_btn-close";
      }
      case OneFlowScreen.SIGN_UP_FORM: {
        return "oneFlow_modal-signUpEmail_btn-close";
      }
      case OneFlowScreen.MISSING_EMAIL: {
        return "oneFlow_modal-MissingEmail_btn-close";
      }
      case OneFlowScreen.CHOOSE_M2: {
        return "oneFlow_modal-ProtectOnce_btn-close";
      }
      case OneFlowScreen.PAYMENT: {
        return "oneFlow_modal-Payment_btn-close";
      }
      case OneFlowScreen.IDENTITY: {
        return this.isGroupToken
          ? "oneFlow_modal-identityGroup_btn-close"
          : "oneFlow_modal-identity_btn-close";
      }
      case OneFlowScreen.GIFT_SUBSCRIPTION_SUCCESS: {
        return "oneFlow_modal-giftSubscriptionSuccess_btn-Close";
      }
      case OneFlowScreen.CREATE_GROUP: {
        return "oneFlow_modal-createOrgLoggedIn_btn-close";
      }
      case OneFlowScreen.CREATE_ORG: {
        return "oneFlow_modal-createOrg_btn-close";
      }
      case OneFlowScreen.START: {
        return "oneFlow_modal-startScreen_btn-close";
      }
      default: {
        return undefined;
      }
    }
  }

  @computed get actAsOptions(): IActAsOptions[] {
    const { user } = this.userSessionStore;
    const member = this.memberUserInfo;

    if (!user || !user.isGroupToken) return [];
    if (member) {
      return [
        {
          fullName: user.fullName,
          photo: getResizeImageUrl(user.imageData, {
            width: ONE_FLOW_INDENTITY_AVATAR_WIDTH,
            height: ONE_FLOW_INDENTITY_AVATAR_HEIGHT,
          }),
          actAs: OneFlowActAs.GROUP,
          dataTrackId: "oneFlow_modal-identityGroup_select-GroupProfile",
        },
        {
          ...member,
          actAs: OneFlowActAs.USER,
          dataTrackId: "oneFlow_modal-identityGroup_dropdown-selectUserProfile",
        },
      ];
    }
    return [
      {
        fullName: user.fullName,
        photo: getResizeImageUrl(user.imageData, {
          width: ONE_FLOW_INDENTITY_AVATAR_WIDTH,
          height: ONE_FLOW_INDENTITY_AVATAR_HEIGHT,
        }),
        actAs: OneFlowActAs.GROUP,
        dataTrackId: "oneFlow_modal-identityGroup_select-GroupProfile",
      },
    ];
  }

  @action.bound updateActAsSelected(actAs: OneFlowActAs): void {
    this.actAs = actAs;
  }

  @computed get triggerPointType(): "button" | "url" {
    if (this.isURLSpecific) {
      return "url";
    }
    return "button";
  }

  @computed get triggerPoint(): string {
    if (!isBrowser()) return "";
    if (this.triggerPointType === "url") {
      return window.location.href;
    }
    return this.donateStore.triggerPointDataCy;
  }

  @action.bound snowplowCaptureUonCollectedEvent(): void {
    const data: IUonCollectedData = {
      uonValue: SUBSCRIBE_UON_PER_CODE,
      wallet: this.userSessionStore.user?.id || "",
      protectedBy: this.donateStore.protectedByPersonalSubscription,
      sponsoredBy: this.userSessionStore.user?.vanityName || "",
      registeredTo: this.userSessionStore.user?.vanityName || "",
      codeType: "oneflow_subscribe",
      triggerPoint: "oneflow_model",
    };
    snowplowCaptureUonCollectedEvent(data);
  }

  @action.bound prepareProfileDonation(
    userProfile: UserModel,
    campaignId?: string,
  ) {
    if (!userProfile) return;

    // donate to charity group's campaign
    if (
      userProfile?.userType === UserType.GROUP_ADAPTER &&
      userProfile.group?.profileType === GroupProfileType.CHARITY
    ) {
      this.donateStore.campaignSource = null;
      this.donateStore.charityProfile = {
        name: userProfile.fullName,
        id: userProfile.id,
      };
      return;
    }

    // donate to NPO's reserves
    if (
      userProfile?.userType === UserType.GROUP_ADAPTER &&
      userProfile.group?.profileType === GroupProfileType.NPO
    ) {
      this.donateStore.campaignSource = null;

      if (userProfile.isDisabledProtect) {
        this.donateStore.updateNpoId("");
      } else {
        this.donateStore.updateNpoId(userProfile.id);
      }
      return;
    }

    if (!campaignId) return;

    this.donateStore.campaignSource = userProfile.isDisabledProtect
      ? null
      : { fullName: userProfile.fullName, campaignId };
  }

  @action.bound prepareReserveDonation(
    userProfile: UserModel,
    reserveId?: string,
  ) {
    if (reserveId && !userProfile?.isDisabledProtect) {
      this.donateStore.updateReserveId(reserveId);
      return;
    }

    this.donateStore.updateReserveId("");
  }

  @action.bound onStartScreenContinueBtnClicked = flow(function* (
    this: ModalOneFlowPresenter,
  ) {
    if (!this.loggedInUser) {
      if (this.featureFlagStore.flags.enableLoginWithQRCode) {
        this.theNavbarStore.onOpenLoginModal();
      } else {
        this.updateCurrentScreen(OneFlowScreen.SIGN_UP);
      }
      return;
    }

    if (!this.isGroupToken && this.actAs !== OneFlowActAs.GROUP) {
      this.updatePaymentScreen();

      const existingGroupId = this.userSessionStore.user?.group?.id;
      yield this.donateStore.prepareAccountOneFlow(
        {
          type: this.oneFlowType || OneFlowType.PROTECT,
          actAs: this.payloadNewGroup ? OneFlowActAs.GROUP : this.payloadActAs,
          signupEmail: this.payloadSignupEmail,
          newGroup: this.payloadNewGroup,
          existingGroupId,
          sessionSource: this.sessionSource,
        },
        this.groupImage,
      );
      return;
    }

    if (!this.actAs) {
      this.updateActAsSelected(
        this.isGroupToken ? OneFlowActAs.GROUP : OneFlowActAs.USER,
      );
    }
    yield this.getMemberUserInfo();
    this.updateCurrentScreen(OneFlowScreen.IDENTITY);
  });

  @action.bound onWhoRuScreenContinueBtnClicked = flow(function* (
    this: ModalOneFlowPresenter,
  ) {
    if (this.createAccountType === CreateAccountType.ORGANIZATION) {
      this.updateCurrentScreen(OneFlowScreen.CREATE_ORG);
      return;
    }

    if (!this.loggedInUser) {
      this.updateCurrentScreen(OneFlowScreen.SIGN_UP);
      return;
    }

    if (!this.isGroupToken && this.actAs !== OneFlowActAs.GROUP) {
      this.updatePaymentScreen();

      const existingGroupId = this.userSessionStore.user?.group?.id;
      yield this.donateStore.prepareAccountOneFlow(
        {
          type: this.oneFlowType || OneFlowType.PROTECT,
          actAs: this.payloadNewGroup ? OneFlowActAs.GROUP : this.payloadActAs,
          signupEmail: this.payloadSignupEmail,
          newGroup: this.payloadNewGroup,
          existingGroupId,
          sessionSource: this.sessionSource,
        },
        this.groupImage,
      );
      return;
    }

    if (!this.actAs) {
      this.updateActAsSelected(
        this.isGroupToken ? OneFlowActAs.GROUP : OneFlowActAs.USER,
      );
    }
    yield this.getMemberUserInfo();
    this.updateCurrentScreen(OneFlowScreen.IDENTITY);
  });

  @action.bound onOneFlowSignUpScreenEmailBtnClicked() {
    this.updateCurrentScreen(OneFlowScreen.SIGN_UP_FORM);
  }

  @action.bound onLoggedInStatusChangedWhoRuScreen() {
    if (!this.loggedInUser || this.isPreparingAccount) {
      return;
    }

    if (this.userHasEmail) {
      this.onWhoRuScreenContinueBtnClicked();
      return;
    }

    this.updateCurrentScreen(OneFlowScreen.MISSING_EMAIL);
  }

  @action.bound onLoggedInStatusChangedSignUpScreen = flow(function* (
    this: ModalOneFlowPresenter,
  ) {
    if (!this.loggedInUser || this.isPreparingAccount) {
      return;
    }

    if (!this.isGroupToken && this.actAs !== OneFlowActAs.GROUP) {
      this.updatePaymentScreen();

      const existingGroupId = this.userSessionStore.user?.group?.id;
      yield this.donateStore.prepareAccountOneFlow(
        {
          type: this.oneFlowType || OneFlowType.PROTECT,
          actAs: this.payloadNewGroup ? OneFlowActAs.GROUP : this.payloadActAs,
          signupEmail: this.payloadSignupEmail,
          newGroup: this.payloadNewGroup,
          existingGroupId,
          sessionSource: this.sessionSource,
        },
        this.groupImage,
      );
      return;
    }

    if (!this.actAs) {
      this.updateActAsSelected(
        this.isGroupToken ? OneFlowActAs.GROUP : OneFlowActAs.USER,
      );
    }
    yield this.getMemberUserInfo();
    this.updateCurrentScreen(OneFlowScreen.IDENTITY);
  });

  @observable promotionSeriesType: PromotionSeriesType | undefined;
  @observable isLimitedEditionNoteShowing: boolean = false;
  @action.bound onLimitedEditionNoteBubbleClicked(): void {
    this.isLimitedEditionNoteShowing = !this.isLimitedEditionNoteShowing;
  }
  @action.bound onOutSideLimitedEditionNoteBubbleClicked(): void {
    this.isLimitedEditionNoteShowing = !this.isLimitedEditionNoteShowing;
  }
  @observable promotionUonImageUrl: string | undefined = undefined;
}
