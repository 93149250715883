/* eslint-disable jsx-a11y/media-has-caption */
import { observer } from "mobx-react-lite";
import VimeoPlayer from "react-player/vimeo";

import image from "../../../assets/img/landing-page-earth.png";
import uonImage from "../../../assets/img/landing-page-uon.png";
import { etBlack, etWhite } from "../../../shared/colors";
import { isBrowser } from "../../../shared/helpers/isBrowser";
import { protectPageSectionOneNumberAnimation } from "../../../shared/helpers/protectPageSectionOneNumberAnimation";
import { DownloadAppButton } from "../../DownloadAppButtons/DownloadAppButton";
import { HomeHeaderCardCountAnimation } from "../../HomeHeaderCardCounter/HomeHeaderCardCountAnimation";
import { IconSvg } from "../../IconSvg/IconSvg";
import { TheLoader } from "../../TheLoader/TheLoader";
import { sc } from "./SectionOne.styled";

export interface LandingPageSectionOneDriver {
  protectedCount: number | null;
  shouldRenderAppStore: boolean;
  shouldRenderGooglePlay: boolean;
  shouldRenderVideoPlayer: boolean;
  onPlayVideoButtonClicked(): void;
  onOverlayClicked(): void;
}
interface IProps {
  driver: LandingPageSectionOneDriver;
}

const getUserAgent = function () {
  if (!isBrowser()) return "";

  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera || null;

  if (!userAgent) return "";

  return userAgent;
};

const isMobileOrTablet = function () {
  const userAgent = getUserAgent();

  const userAgentPart = userAgent.slice(0, 4);

  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      userAgent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[23]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      userAgentPart,
    )
  );
};

export const SectionOne = observer((props: IProps) => {
  const {
    protectedCount,
    shouldRenderAppStore,
    shouldRenderGooglePlay,
    onPlayVideoButtonClicked,
  } = props.driver;

  return (
    <sc.Container className="visual-reg">
      <sc.ImageWrapper>
        <sc.Image src={image} />
      </sc.ImageWrapper>
      <sc.CounterContainer>
        <sc.CounterWrapper>
          {protectedCount === null ? (
            <TheLoader className="visual-reg-remove" />
          ) : (
            <>
              {protectPageSectionOneNumberAnimation(protectedCount).map(
                (count, i) => (
                  <HomeHeaderCardCountAnimation
                    dataCy={"LandingPage-CountNumber"}
                    flags={{ counterFlapEffectDuration: 0 }}
                    noAnimationCounter={true}
                    countNumbers={count.countNumbers}
                    lastNumber={count.lastNumber}
                    key={`${count.lastNumber}${i}`}
                    borderColor={etBlack}
                  />
                ),
              )}
              <sc.CounterMeterLogo
                data-cy="HomeHeaderCardCountAnimation-SquareMeterLogo"
                src={require("../../../assets/img/m2-black.png")}
                alt="Home Header Card Counter m²"
              />
            </>
          )}
        </sc.CounterWrapper>
        <sc.CounterDescription>
          Total protected by you on earth community
        </sc.CounterDescription>
      </sc.CounterContainer>
      <sc.DownloadAppContainer>
        <sc.DownloadAppText>
          <sc.BigText>You on Earth</sc.BigText>
          <sc.SmallText>Collect uon. Protect Earth.</sc.SmallText>
        </sc.DownloadAppText>
        <sc.DowloadOptionsContainer>
          {shouldRenderAppStore && (
            <DownloadAppButton
              store="app-store"
              width={152}
              height={47}
              // storeUrl="https://apps.apple.com/app/uon-earth/id6470965614"
            />
          )}
          {shouldRenderGooglePlay && (
            <DownloadAppButton
              store="google-play"
              width={152}
              height={47}
              // storeUrl="https://play.google.com/store/apps/details?id=uon.earth"
            />
          )}
        </sc.DowloadOptionsContainer>
      </sc.DownloadAppContainer>
      <sc.LearnMoreButtonContainer>
        <sc.ArrowButton>
          <sc.IconWrapper>
            <IconSvg
              icon="arrow-up-v2"
              width="24px"
              height="24px"
              fillColor={etWhite}
              pointer
            />
          </sc.IconWrapper>
        </sc.ArrowButton>
      </sc.LearnMoreButtonContainer>
      <sc.LearnMoreContainer>
        <sc.LearnMoreYouOnEarth>
          <sc.MovieContainer>
            <sc.MovieWrapper className="visual-reg-pass">
              <VimeoPlayer
                video="fa92bea5a8"
                url={"https://vimeo.com/892171682/fa92bea5a8?share=copy"}
                height="100%"
                width="100%"
                muted={true}
                config={{
                  playerOptions: {
                    autoplay: isMobileOrTablet() ? false : true,
                    loop: true,
                    quality: "auto",
                    responsive: true,
                  },
                }}
              />
            </sc.MovieWrapper>
          </sc.MovieContainer>
          <sc.GridContainer>
            <sc.UonGrid>
              <IconSvg icon="uon-grid" width="120%" height="142%" />
            </sc.UonGrid>
          </sc.GridContainer>
          <sc.VideoOverlay />
          <sc.YouOnEarth>
            Ever dreamed of protecting your own piece of nature?
            <br />
            <sc.Span>Now you can.</sc.Span>
          </sc.YouOnEarth>
          <sc.ForTheFirstTime>
            For the first time in history everybody can protect Earth.{" "}
            <sc.Span>Meter x meter.</sc.Span>
          </sc.ForTheFirstTime>
          <sc.LearnMoreWatch onClick={onPlayVideoButtonClicked}>
            WATCH MOVIE
          </sc.LearnMoreWatch>
        </sc.LearnMoreYouOnEarth>
        <sc.LearnMoreProtect>
          <sc.LearnMoreProtectContent>
            <sc.UonImage src={uonImage} alt="uon-image" />
            <sc.ProtectTitle>
              Protect 1 m² of nature for only €1.20
            </sc.ProtectTitle>
            <sc.ProtectSubtitle>
              With every m² you protect, you collect a uon: a unique digital
              proof of protection of a geocoded unit of nature registered in
              your name.
            </sc.ProtectSubtitle>
            <sc.DowloadOptionsContainer>
              {shouldRenderAppStore && (
                <DownloadAppButton
                  store="app-store"
                  width={152}
                  height={47}
                  // storeUrl="https://apps.apple.com/app/uon-earth/id6470965614"
                />
              )}
              {shouldRenderGooglePlay && (
                <DownloadAppButton
                  store="google-play"
                  width={152}
                  height={47}
                  // storeUrl="https://play.google.com/store/apps/details?id=uon.earth"
                />
              )}
            </sc.DowloadOptionsContainer>
          </sc.LearnMoreProtectContent>
        </sc.LearnMoreProtect>
      </sc.LearnMoreContainer>
    </sc.Container>
  );
});
